<h2 mat-dialog-title tabindex="0" class="narrow" data-test-id="create-verfuegung-dialog-title">Verfügung erstellen mit
	folgenden Aufgaben</h2>

<form [formGroup]="form">
	<mat-dialog-content>
		<div class="wizard">
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="finanzsanktionsliste-checkbox"
			              [formControl]="formService.controlAufgabeFinanzsanktionsliste">
				Finanzsanktionsliste und SIS geprüft
			</mat-checkbox>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-zertifikat-checkbox"
			              [formControl]="formService.controlAufgabeZertifikat">
				Zertifikat / Schiffsbrief
			</mat-checkbox>
			<schir-int-client-aufgabe-zertifikat-editor data-test-id="verfuegung-aufgabe-zertifikat">
			</schir-int-client-aufgabe-zertifikat-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-altes-zertifikat-checkbox"
			              [formControl]="formService.controlAufgabeAltesZertifikat">
				Altes Zertifikat <span>{{ aufgabeTypStaticTaetigkeitLabel[aufgabenTypen.ALTES_ZERTIFIKAT] }}</span>
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor data-test-id="verfuegung-altes-zertifikat"
			                                                        [aufgabenTyp]="aufgabenTypen.ALTES_ZERTIFIKAT">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark"
			              data-test-id="verfuegung-zertifikatsauszug-checkbox"
			              [formControl]="formService.controlAufgabeZertifikatsauszug">
				Zertifikatsauszug <span>fertigen</span>
			</mat-checkbox>
			<schir-int-client-aufgabe-versand-editor data-test-id="verfuegung-zertifikatsauszug"
			                                         [aufgabenTyp]="aufgabenTypen.ZERTIFIKATSAUSZUG">
			</schir-int-client-aufgabe-versand-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark"
			              data-test-id="verfuegung-abschrift-zertifikatsauszug-checkbox"
			              [formControl]="formService.controlAufgabeAbschriftZertifikatsauszug">
				Begl. Abschrift <span>des Zertifikatsauszugs fertigen</span>
			</mat-checkbox>
			<schir-int-client-aufgabe-versand-editor class="push-top-taetigkeit"
			                                         data-test-id="verfuegung-abschrift-zertifikatsauszug"
			                                         [aufgabenTyp]="aufgabenTypen.ABSCHRIFT_ZERTIFIKATSAUSZUG">
			</schir-int-client-aufgabe-versand-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-alter-auszug-checkbox"
			              [formControl]="formService.controlAufgabeAlterAuszug">
				Alten Auszug <span>{{ aufgabeTypStaticTaetigkeitLabel[aufgabenTypen.ALTER_AUSZUG] }}</span>
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor data-test-id="verfuegung-alter-auszug"
			                                                        [aufgabenTyp]="aufgabenTypen.ALTER_AUSZUG">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<hr>
			<mat-checkbox color="primary" (change)="onBescheinigungChecked($event)">Bescheinigung fertigen</mat-checkbox>
			<div *ngIf="formService.bescheinigung.enabled" class="editor">
				<mat-divider [vertical]="true" />
				<schir-int-client-bescheinigung-editor [formGroup]="formService.bescheinigung" />
			</div>
			<mat-checkbox color="primary" class="focus-inwards-dark"
			              data-test-id="verfuegung-registerabschrift-checkbox"
			              [formControl]="formService.controlAufgabeRegisterabschrift">
				Registerabschrift <span>fertigen</span>
			</mat-checkbox>
			<schir-int-client-aufgabe-abschrift-editor data-test-id="verfuegung-abschrift">
			</schir-int-client-aufgabe-abschrift-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark"
			              data-test-id="verfuegung-eintragungsmitteilung-checkbox"
			              [formControl]="formService.controlAufgabeEintragungsmitteilung">
				Eintragungsmitteilung <span>fertigen</span>
			</mat-checkbox>
			<schir-int-client-aufgabe-mitteilung-editor class="mitteilung-sub"
			                                            data-test-id="verfuegung-eintragungsmitteilung">
			</schir-int-client-aufgabe-mitteilung-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-messbrief-checkbox"
			              [formControl]="formService.controlAufgabeMessbrief">
				Messbrief
			</mat-checkbox>
			<schir-int-client-aufgabe-messbrief-editor data-test-id="verfuegung-messbrief">
			</schir-int-client-aufgabe-messbrief-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-sonstige-checkbox"
			              [formControl]="formService.controlAufgabeSonstige">
				Sonstige
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor data-test-id="verfuegung-sonstige"
			                                                        [aufgabenTyp]="aufgabenTypen.SONSTIGE">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark"
			              data-test-id="verfuegung-rechnungsstellung-checkbox"
			              [formControl]="formService.controlAufgabeRechnungsstellung">
				Rechnungsstellung <span>fertigen</span>
			</mat-checkbox>
			<schir-int-client-aufgabe-rechnungsstellung-editor data-test-id="verfuegung-rechnungsstellung">
			</schir-int-client-aufgabe-rechnungsstellung-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-kosten-checkbox"
			              [formControl]="formService.controlAufgabeKosten">
				Kosten
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor data-test-id="verfuegung-kosten"
			                                                        [aufgabenTyp]="aufgabenTypen.KOSTEN">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-wiedervorlage-checkbox"
			              [formControl]="formService.controlAufgabeWiedervorlage">
				Wiedervorlagefrist <span>setzen</span>
			</mat-checkbox>
			<schir-int-client-aufgabe-wiedervorlage-editor data-test-id="verfuegung-wiedervorlage"
			                                               [aufgabenTyp]="aufgabenTypen.WIEDERVORLAGE">
			</schir-int-client-aufgabe-wiedervorlage-editor>
			<hr>
			<mat-checkbox color="primary" class="focus-inwards-dark" data-test-id="verfuegung-ablage-checkbox"
			              [formControl]="formService.controlAufgabeAblage">
				Ablage
			</mat-checkbox>
			<schir-int-client-aufgabe-with-static-taetigkeit-editor [aufgabenTyp]="aufgabenTypen.ABLAGE"
			                                                        data-test-id="verfuegung-ablage">
			</schir-int-client-aufgabe-with-static-taetigkeit-editor>
			<mat-form-field appearance="outline">
				<mat-label>Notiz zur Verfügung</mat-label>
				<textarea matInput data-test-id="verfuegungs-notiz-input"
				          [formControl]="formService.controlVerfuegungNotiz"></textarea>
			</mat-form-field>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions>
		<button mat-stroked-button (click)="onCancel()" type="button" class="focus-inwards-dark"
		        data-test-id="abbrechen-button">
			Abbrechen
		</button>
		<button mat-flat-button color="primary" type="button" (click)="onSave()" [disabled]="!isValid()"
		        class="icon-right focus-inwards-dark" data-test-id="speichern-button">
			Speichern
		</button>
	</mat-dialog-actions>
</form>
