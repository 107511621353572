import {toResource} from '@schir-int-client/test-helpers';
import {isNil} from 'lodash-es';
import {VerfahrenListResource} from '@schir-int-client/verfahren-shared';
import {Resource} from '@ngxp/rest';
import {VerfuegungResource} from '@schir-int-client/verfuegung-shared';
import {Action, Store} from '@ngrx/store';

class Dummy {}

export function createDummyResource(linkRelations: string[] = []) {
	return toResource(new Dummy(), linkRelations);
}

export interface StateResource<T> {
	resource: T;
	reload: boolean;
	loading: boolean;
	loaded: boolean;
}

export function createEmptyStateResource<T>(loading: boolean = false): StateResource<T> {
	return createStateResource(<T>null, loading);
}

export function createStateResource<T>(resource: T, loading: boolean = false): StateResource<T> {
	return { loading, loaded: !isNil(resource), reload: false, resource };
}

/**
 * Prevents parallel loading of same resource. Note that just using doIfLoadingRequired does not work because that method uses
 * attributes of the state which can be set only _after_ the load action is received. This class provides an own guard while retaining
 * the whole behaviour of doIfLoadingRequired for compatibility.
 */
export class LoadingGuard<T> {
	private currentlyRequestedUri: string;

	constructor(private store: Store) {}

	/**
	 * Triggers loading the resource if necessary.
	 * @param res resource to load if necessary
	 * @param actionProvider produces the action which indicates to load the resource
	 * @param uri identifies the currently loaded resource in case the action can load different values. It is not required that this value is indeed an uri!
	 * @return true if a reload is under way
	 */
	public mustLoadFirst(res: StateResource<T>, actionProvider: () => Action, uri = 'default'): boolean {
		const result = doIfLoadingRequired(res, () => {
			if (uri !== this.currentlyRequestedUri) {
				this.currentlyRequestedUri = uri;
				this.store.dispatch(actionProvider());
			}
		});
		if (res.loaded) {
			this.currentlyRequestedUri = '';
		}
		return result;
	}

	public reset() {
		this.currentlyRequestedUri = null;
	}
}


export function doIfLoadingRequired(stateResource: StateResource<any>, runable: () => void): boolean {
	if (isLoadingRequired(stateResource)) {
		runable();
		return true;
	}
	return false;
}

export function isLoadingRequired(stateResource: StateResource<any>): boolean {
	if (stateResource.loading) {
		return false;
	}
	if (stateResource.reload) {
		return true;
	}
	return !stateResource.loaded;
}

export function getFirstVerfahrenInList(verfahrenList: VerfahrenListResource) {
	if (verfahrenList && verfahrenList._embedded && verfahrenList._embedded['verfahrenList']) {
		return verfahrenList._embedded['verfahrenList'][0];
	}
	return null;
}

//checks if the selected verfuegung is part of the verfuegungen of the current vorgang
export function belongsToCurrentVorgang(verfuegungList: Resource | Resource [], selectedVerfuegung: VerfuegungResource): boolean {
	let contained: boolean = false;

	Object.keys(verfuegungList).forEach(value => {
		if (selectedVerfuegung && verfuegungList[value] && verfuegungList[value]._links.self.href == selectedVerfuegung._links.self.href) {
			contained = true;
		}
	});
	return contained;
}

export function selfLink(index: any, item : Resource){
	return item._links.self.href;
}
