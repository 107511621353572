<div>
	<span *ngIf="body.anEigentuemerNeuSenden" data-test-id="eigentuemer-neu">Eigentümer neu {{eigentuemerNeu}}</span>
	<span *ngIf="body.anEigentuemerAltSenden" data-test-id="eigentuemer-alt">Eigentümer alt {{eigentuemerAlt}}</span>
	<span *ngIf="body.anEingetragenerEigentuemerSenden" data-test-id="eingetragener-eigentuemer">Eingetragener Eigentümer {{eingetragenerEigentuemer}}</span>
	<span *ngIf="body.anGlaeubigerNeuSenden" data-test-id="glaeubiger-neu">Gläubiger neu {{glaeubigerNeu}}</span>
	<span *ngIf="body.anGlaeubigerAltSenden" data-test-id="glaeubiger-alt">Gläubiger alt {{glaeubigerAlt}}</span>
	<span *ngIf="body.anNotarSenden" data-test-id="notar">Notar {{notar}}</span>
	<span *ngIf="body.anBehoerdenSenden" data-test-id="behoerden">Behörden</span>
	<span *ngIf="body.anBshSenden" data-test-id="bsh">{{mitteilungEmpfaenger.BSH}} {{bsh}}</span>
	<span *ngIf="body.anRechtsanwaelteSenden" data-test-id="rechtsanwaelte">Rechtsanwälte {{rechtsanwaelte}}</span>
</div>
