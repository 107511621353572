import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {getUrl, hasLink} from '@ngxp/rest';
import {KontaktKategorieLabel} from '@schir-int-client/adressverwaltung-shared';
import {
	AufgabeFacade,
	AufgabeLinkRel,
	AufgabeMessages,
	AufgabenTyp,
	AufgabeResource,
	AufgabeStatusLabel,
	AufgabeTypStaticTaetigkeitLabel,
} from '@schir-int-client/aufgabe-shared';
import {createEmptyStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {DialogService} from '@schir-int-client/tech';
import {VerfahrenFacade} from '@schir-int-client/verfahren-shared';
import {KorrespondenzVorlageFacade, KorrespondenzVorlageResource} from '@schir-int-client/korrespondenz-shared';
import {isNil, isUndefined} from 'lodash-es';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {filter, first, map, switchMap, take} from 'rxjs/operators';
import {VorlageFacade, VorlageResource} from '@schir-int-client/vorlage-shared';

@Component({
	selector: 'schir-int-client-aufgabe-in-verfuegung',
	templateUrl: './aufgabe-in-verfuegung.component.html',
	styleUrls: ['./aufgabe-in-verfuegung.component.scss'],
})
export class AufgabeInVerfuegungComponent implements OnInit, OnChanges, OnDestroy {
	readonly aufgabeTypStaticTaetigkeitLabel = AufgabeTypStaticTaetigkeitLabel;
	readonly kontaktKategorieLabel = KontaktKategorieLabel;
	readonly aufgabenTyp = AufgabenTyp;
	readonly staticTaetigkeitLabel = AufgabeTypStaticTaetigkeitLabel;

	@Input() aufgabe: AufgabeResource;

	@Output() openKorrespondenz: EventEmitter<void> = new EventEmitter();
	@Output() openAufgabeDialog: EventEmitter<void> = new EventEmitter();

	private subscriptions: Subscription[] = [];

	isSelected: Observable<boolean>;
	aufgabeStatusLabel = AufgabeStatusLabel;
	isMoreButtonsVisible = false;
	linkRel = AufgabeLinkRel;
	verfahrenUri: Observable<string>;
	vorlage: Observable<VorlageResource>;

	audioAlert: Subject<string> = new Subject<string>();

	constructor(public router: Router,
	            private facade: AufgabeFacade,
	            verfahrenFacade: VerfahrenFacade,
	            private dialogService: DialogService,
	            private korrespondenzVorlageFacade: KorrespondenzVorlageFacade,
	            private vorlageFacade: VorlageFacade) {
		this.verfahrenUri = verfahrenFacade.selectedVerfahren.pipe(
			map(selectedVerfahren => isNil(selectedVerfahren) ? null : btoa(getUrl(selectedVerfahren))),
		);
	}

	ngOnInit() {
		this.isSelected = this.facade.isAufgabeSelected(this.aufgabe);
		this.subscriptions.push(this.isSelected.pipe(first()).subscribe(selected => {
			if (selected) {
				this.subscriptions.push(this.verfahrenUri.pipe(first()).subscribe(uri => {
						this.setAufgabe();
						this.router.navigate(['/verfahren', uri, { outlets: { aufgabe: this.aufgabeUri } }]);
					},
				));
			}
		}));
	}

	ngOnChanges() {
		if (!isUndefined(this.aufgabe) && hasLink(this.aufgabe, AufgabeLinkRel.KORRESPONDENZ_VORLAGE)) {
			this.vorlage = this.korrespondenzVorlageFacade.getKorrespondenzVorlageByAufgabe(this.aufgabe).pipe(
				filter(korrespondenzVorlageResource => korrespondenzVorlageResource.loaded),
				switchMap(korrespondenzVorlageResource => korrespondenzVorlageResource.resource.vorlageId.endsWith('-1') ? of(<VorlageResource>{ name: 'Benutzerdefiniert' }) : this.vorlageFacade.getVorlage(korrespondenzVorlageResource.resource)),
			);
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	get aufgabeUri() {
		return btoa(getUrl(this.aufgabe));
	}

	get korrespondenzVorlage(): Observable<StateResource<KorrespondenzVorlageResource>> {
		if (!isUndefined(this.aufgabe) && hasLink(this.aufgabe, AufgabeLinkRel.KORRESPONDENZ_VORLAGE)) {
			return this.korrespondenzVorlageFacade.getKorrespondenzVorlageByAufgabe(this.aufgabe);
		}
		return of(createEmptyStateResource<KorrespondenzVorlageResource>());
	}

	get hasZertifikatBody(): boolean {
		if (!isNil(this.aufgabe.body)) {
			return 'sendenAn' in this.aufgabe.body;
		}
	}

	get hasVersandBody(): boolean {
		if (!isNil(this.aufgabe.body)) {
			return 'empfaenger' in this.aufgabe.body;
		}
	}

	get isRegisterabschrift(): boolean {
		if (!isNil(this.aufgabe.body)) {
			return this.aufgabe.typ === AufgabenTyp.REGISTERABSCHRIFT;
		}
	}

	get isEintragungsMitteilung(): boolean {
		if (!isNil(this.aufgabe.body)) {
			return this.aufgabe.typ === AufgabenTyp.EINTRAGUNGSMITTEILUNG;
		}
	}

	get isWiedervorlage(): boolean {
		if (!isNil(this.aufgabe.body)) {
			return this.aufgabe.typ === AufgabenTyp.WIEDERVORLAGE;
		}
	}

	get isRechnungsstellung(): boolean {
		if (!isNil(this.aufgabe.body)) {
			return this.aufgabe.typ === AufgabenTyp.RECHNUNGSSTELLUNG;
		}
	}

	markAsFreigegeben(): void {
		this.facade.markAsFreigegeben(this.aufgabe);
		this.audioAlert.next('Die Aufgabe wurde als freigegeben markiert.');
	}

	markAsErledigt(): void {
		this.facade.markAsErledigt(this.aufgabe);
		this.audioAlert.next('Die Aufgabe wurde als erledigt markiert.');
	}

	setAufgabe(): void {
		this.facade.setAufgabe(this.aufgabe);
	}

	toggleMoreButtons() {
		this.isMoreButtonsVisible = !this.isMoreButtonsVisible;
	}

	openKorrespondenzInAufgaben() {
		this.openKorrespondenz.emit();
	}

	deleteKorrespondezVorlage(korrespondenzVorlage: KorrespondenzVorlageResource) {
		this.korrespondenzVorlageFacade.deleteKorrespondenzVorlage(korrespondenzVorlage);
	}

	uploadKorrespondenzVorlage(file: File, korrespondenzVorlage: KorrespondenzVorlageResource) {
		this.facade.setAufgabe(this.aufgabe);
		this.korrespondenzVorlageFacade.uploadKorrespondenzVorlage(korrespondenzVorlage, file);
	}

	delete() {
		this.subscriptions.push(this.dialogService.openDialogYesNo(AufgabeMessages.DELETE_AUFGABE).pipe(take(1)).subscribe(data => {
			if (data.agree) {
				this.facade.deleteAufgabe(this.aufgabe);
			}
		}));
	}

	editAufgabe() {
		this.facade.setAufgabe(this.aufgabe);
		this.openAufgabeDialog.emit();
	}
}
