import {Resource} from '@ngxp/rest';
import {VerfahrenSearchKey, ZaehlblattAktionTyp} from '@schir-int-client/verfahren-shared';

export interface EipConfig {
	organizationalEntityId: string,
	courtId: string,
	courtLocation: string,
	courtType: string,
	courtTypeId: string,
	courtDescription: string,
	eaktePrefix: string,
	url: string,
	clientId: string
}

export interface EkpConfig {
	enabled: boolean,
	inbox: string,
	errorFolder: string,
	backupFolder: string,
	checkIntervall: number,
}

export interface Features {
	lfpr: boolean,
	registers: string[]
	behoerdeAutoKontakt: string[]
	searchableFields: VerfahrenSearchKey[]
	standortAkteValuesByRegister: { [register: string]: string[] }
	zaehlblattAktionTypenByRegister: { [register: string]: ZaehlblattAktionTyp[] }
}

export interface ApiRoot {
	version: string,
	buildTime: string,
	eipConfig: EipConfig,
	ekpConfig: EkpConfig,
	features: Features,

	production: boolean,
	ort: string,
	amtsgerichtnummer: number,
	xjustizId: string,
	schirHostname: string,

	shortAktenzeichen: boolean,
	showLogoutButton: boolean,

	// TODO Remove as soon as the window title configuration in eIP is updated
	useLegacyWindowTitles: boolean
}

export interface ApiRootResource extends ApiRoot, Resource {}
