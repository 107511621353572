import {Action} from '@ngrx/store';
import {ResourceUri} from '@ngxp/rest';
import {SignatureResource, SignatureValidatorResponseResource, SignaturTaskResource} from './signatur.model';
import {ChangeEntryResource} from '@schir-int-client/register-change';

export enum SignaturActions {
	CREATE_SIGNATUR_TASK = 'CREATE_SIGNATUR_TASK',
	DOWNLOAD_SIGNATUR_TASK_SUCCESS = 'DOWNLOAD_SIGNATUR_TASK_SUCCESS',
	DOWNLOAD_SIGNER_SCRIPT = 'DOWNLOAD_SIGNER_SCRIPT',
	DOWNLOAD_SIGNATURE_TASK = 'DOWNLOAD_SIGNATURE_TASK',
	DOWNLOAD_SIGNATURE = 'DOWNLOAD_SIGNATURE',
	DOWNLOAD_SIGNATURE_SUCCESS = 'DOWNLOAD_SIGNATURE_SUCCESS',
	OPEN_IN_PROGRESS_DIALOG = 'OPEN_IN_PROGRESS_DIALOG',
	CLOSE_IN_PROGRESS_DIALOG = 'CLOSE_IN_PROGRESS_DIALOG',
	CANCEL_SIGNATURE = 'CANCEL_SIGNATURE',
	ACKNOWLEDGE_SIGNATURE_ERROR = 'ACKNOWLEDGE_SIGNATURE_ERROR',
	LOAD_SIGNATURE_VALIDATOR_RESPONSES = 'LOAD_SIGNATURE_VALIDATOR_RESPONSES',
	LOAD_SIGNATURE_VALIDATOR_RESPONSES_SUCCESS = 'LOAD_SIGNATURE_VALIDATOR_RESPONSES_SUCCESS',
	LOAD_SIGNATURE_VALIDATOR_RESPONSES_NOTFOUND = 'LOAD_SIGNATURE_VALIDATOR_RESPONSES_NOT_FOUND'
}

export class DownloadSignaturTaskSuccessAction implements Action {
	type = SignaturActions.DOWNLOAD_SIGNATUR_TASK_SUCCESS;

	constructor(public signaturTask: SignaturTaskResource) { }
}

export class DownloadSignatureTaskAction implements Action {
	type = SignaturActions.DOWNLOAD_SIGNATURE_TASK;

	constructor() { }
}

export class CreateSignaturTaskAction implements Action {
	type = SignaturActions.CREATE_SIGNATUR_TASK;

	constructor(public changeEntries: ResourceUri[]) { }
}

export class DownloadSignatureAction implements Action {
	type = SignaturActions.DOWNLOAD_SIGNATURE;

	constructor(public resource: ChangeEntryResource) { }
}

export class DownloadSignatureSuccessAction implements Action {
	type = SignaturActions.DOWNLOAD_SIGNATURE_SUCCESS;

	constructor(public signature: SignatureResource, public refUrl: string) { }
}

export class OpenInProgressDialogAction implements Action {
	type = SignaturActions.OPEN_IN_PROGRESS_DIALOG;

	constructor() { }
}

export class CloseInProgressDialogAction implements Action {
	type = SignaturActions.CLOSE_IN_PROGRESS_DIALOG;

	constructor() { }
}

export class CancelSignatureAction implements Action {
	type = SignaturActions.CANCEL_SIGNATURE;

	constructor() { }
}

export class DownloadSignerScript implements Action {
	type = SignaturActions.DOWNLOAD_SIGNER_SCRIPT;

	constructor() { }
}

export class AcknowledgeSignatureErrorAction implements Action {
	type = SignaturActions.ACKNOWLEDGE_SIGNATURE_ERROR;

	constructor() { }
}

export class LoadSignatureValidatorResponsesAction implements Action {
	type = SignaturActions.LOAD_SIGNATURE_VALIDATOR_RESPONSES;

	constructor() { }
}

export class LoadSignatureValidatorResponsesSuccessAction implements Action {
	type = SignaturActions.LOAD_SIGNATURE_VALIDATOR_RESPONSES_SUCCESS;

	constructor(public response: SignatureValidatorResponseResource) { }
}

export class LoadSignatureValidatorResponseNotFoundAction implements Action {
	type = SignaturActions.LOAD_SIGNATURE_VALIDATOR_RESPONSES_NOTFOUND;

	constructor() { }
}
