import {Resource, ResourceUri} from '@ngxp/rest';

export interface KorrespondenzCreateUserData {
	datum: Date;
	rechtspfleger: string;
	vorlageId: ResourceUri;
}

export interface KorrespondenzVorlage extends KorrespondenzCreateUserData {
	verfahrenId: ResourceUri;
}

export interface KorrespondenzVorlageResource extends KorrespondenzVorlage, Resource {}
