<div>
	<schir-int-client-checkboxes
			legend="Bescheinigungsart"
			errorText="Bitte Bescheinigungsart wählen"
			[values]="bescheinigungsarten"
			[labels]="aufgabeTypLabel"
			[formGroup]="formGroup.controls.bescheinigungsart" />
	<mat-form-field appearance="outline">
		<mat-label>Notiz zur Aufgabe</mat-label>
		<textarea matInput [formControl]="formGroup.controls.notiz"></textarea>
	</mat-form-field>
</div>
<div>
	<mat-radio-group color="primary" [formControl]="formGroup.controls.versandTaetigkeit">
		<mat-radio-button *ngFor="let taetigkeit of versandTaetigkeiten" [value]="taetigkeit">{{taetigkeit | enumToLabel: versandTaetigkeitLabel}}</mat-radio-button>
	</mat-radio-group>
	<schir-int-client-checkboxes
			legend="Empfänger"
			errorText="Bitte Empfänger wählen"
			[values]="kontaktKategorien"
			[labels]="kontaktKategorieLabel"
			[formGroup]="formGroup.controls.empfaenger" />
	<mat-checkbox color="primary" [formControl]="formGroup.controls.vorabPerFax" (change)="onBescheinigungVorabPerFaxChecked($event)">vorab per Fax</mat-checkbox>
	<schir-int-client-checkboxes
			*ngIf="formGroup.controls.faxEmpfaenger.enabled"
			legend="Fax-Empfänger"
			errorText="Bitte Fax-Empfänger wählen"
			[values]="kontaktKategorien"
			[labels]="kontaktKategorieLabel"
			[formGroup]="formGroup.controls.faxEmpfaenger" />
</div>
