import {Resource} from '@ngxp/rest';

export interface UserProfile {
	anwender: PersonInProfile,
	rechtspfleger: PersonInProfile,
	serviceEinheitTelefon: string
}

export interface PersonInProfile {
	anrede: string;
	name: string;
	amtsbezeichnung: string;
	dienstbezeichnung: string;
	telefon: string;
}

export interface UserProfileResource extends UserProfile, Resource {}
