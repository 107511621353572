import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TechModule} from '@schir-int-client/tech';

@Component({
	selector: 'schir-int-client-checkboxes',
	templateUrl: 'checkboxes.component.html',
	styleUrls: ['checkboxes.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatListModule,
		MatFormFieldModule,
		TechModule,
	],
})
export class CheckboxesComponent {
	@Input() legend: string;
	@Input() errorText: string;
	@Input({ required: true }) values: string[];
	@Input({ required: true }) labels: { [p: string]: string };
	@Input({ required: true }) formGroup: FormGroup<{ control: FormControl<string[]> }>;
}
