import {Component, inject} from '@angular/core';
import {CreateAufgabeFormService} from '@schir-int-client/aufgabe';
import {CreateVerfuegungFormService} from './create-verfuegung.formservice';
import {AufgabeFacade, AufgabenTyp, AufgabeTypStaticTaetigkeitLabel} from '@schir-int-client/aufgabe-shared';
import {BaseEditorComponent} from '@schir-int-client/tech';
import {UntypedFormGroup} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
	selector: 'schir-int-client-create-verfuegung-dialog',
	templateUrl: './create-verfuegung-dialog.component.html',
	styleUrls: ['./create-verfuegung-dialog.component.scss'],
	providers: [CreateVerfuegungFormService, CreateAufgabeFormService],
})
export class CreateVerfuegungDialogComponent extends BaseEditorComponent {
	readonly formService = inject(CreateVerfuegungFormService);
	readonly aufgabeFacade = inject(AufgabeFacade);

	readonly aufgabenTypen = AufgabenTyp;
	readonly aufgabeTypStaticTaetigkeitLabel = AufgabeTypStaticTaetigkeitLabel;

	get form(): UntypedFormGroup {
		return this.formService.form;
	}

	isValid(): boolean {
		return this.formService.checkPreconditions();
	}

	async submit(): Promise<boolean> {
		return this.formService.submit();
	}

	protected onBescheinigungChecked(event: MatCheckboxChange) {
		if (event.checked) {
			this.formService.bescheinigung.enable();
			this.formService.bescheinigung.markAsDirty();
			if (!this.formService.bescheinigung.controls.vorabPerFax.value) {
				this.formService.bescheinigung.controls.faxEmpfaenger.disable();
			}
		} else {
			this.formService.bescheinigung.disable();
		}
	}
}
