import {Injectable, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
	Aufgabe,
	AufgabeFacade,
	AufgabenTyp,
	AufgabeVersandBody,
	AufgabeVersandTaetigkeit,
} from '@schir-int-client/aufgabe-shared';
import {VerfuegungFacade} from '@schir-int-client/verfuegung-shared';
import {CreateAufgabeFormService} from '@schir-int-client/aufgabe';
import {Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {KontaktKategorie} from '@schir-int-client/adressverwaltung-shared';

@Injectable()
export class CreateVerfuegungFormService implements OnDestroy {

	readonly controlAufgabeFinanzsanktionsliste = new UntypedFormControl();
	readonly controlAufgabeZertifikat = new UntypedFormControl();
	readonly controlAufgabeAltesZertifikat = new UntypedFormControl();
	readonly controlAufgabeAlterAuszug = new UntypedFormControl();
	readonly controlAufgabeSonstige = new UntypedFormControl();
	readonly controlAufgabeAblage = new UntypedFormControl();
	readonly controlAufgabeZertifikatsauszug = new UntypedFormControl();
	readonly controlAufgabeAbschriftZertifikatsauszug = new UntypedFormControl();

	// Todo: Sonderlösung für bescheinigung (und statistik). Wird angeglichen, wenn SCHIFF-5158 (FormControls aufräumen) umgesetzt wird
	readonly bescheinigung = new FormGroup({
		bescheinigungsart: new FormGroup({ control: new FormControl<AufgabenTyp[]>([], Validators.required) }),
		notiz: new FormControl(''),
		versandTaetigkeit: new FormControl<AufgabeVersandTaetigkeit>(AufgabeVersandTaetigkeit.SENDEN_AN),
		empfaenger: new FormGroup({ control: new FormControl<KontaktKategorie[]>([], Validators.required) }),
		vorabPerFax: new FormControl(false),
		faxEmpfaenger: new FormGroup({ control: new FormControl<KontaktKategorie[]>([], Validators.required) }),
	});

	readonly controlAufgabeRegisterabschrift = new UntypedFormControl();
	readonly controlAufgabeWiedervorlage = new UntypedFormControl();
	readonly controlAufgabeEintragungsmitteilung = new UntypedFormControl();
	readonly controlAufgabeMessbrief = new UntypedFormControl();
	readonly controlAufgabeRechnungsstellung = new UntypedFormControl();
	readonly controlAufgabeKosten = new UntypedFormControl();
	readonly controlVerfuegungNotiz = new UntypedFormControl();

	form: UntypedFormGroup = new UntypedFormGroup({
		controlAufgabeZertifikat: this.controlAufgabeZertifikat,
		controlAufgabeAltesZertifikat: this.controlAufgabeAltesZertifikat,
		controlAufgabeAlterAuszug: this.controlAufgabeAlterAuszug,
		controlAufgabeSonstige: this.controlAufgabeSonstige,
		controlAufgabeAblage: this.controlAufgabeAblage,
		controlAufgabeZertifikatsauszug: this.controlAufgabeZertifikatsauszug,
		controlAufgabeAbschriftZertifikatsauszug: this.controlAufgabeAbschriftZertifikatsauszug,
		bescheinigung: this.bescheinigung,
		controlAufgabeRegisterabschrift: this.controlAufgabeRegisterabschrift,
		controlAufgabeWiedervorlage: this.controlAufgabeWiedervorlage,
		controlAufgabeEintragungsmitteilung: this.controlAufgabeEintragungsmitteilung,
		controlAufgabeMessbrief: this.controlAufgabeMessbrief,
		controlAufgabeRechnungsstellung: this.controlAufgabeRechnungsstellung,
		controlAufgabeKosten: this.controlAufgabeKosten,
		controlVerfuegungNotiz: this.controlVerfuegungNotiz,
	});

	private typeControlsSubscriptions = new Subscription();

	constructor(private facade: VerfuegungFacade, private aufgabeFormService: CreateAufgabeFormService, private aufgabeFacade: AufgabeFacade) {
		this.bescheinigung.disable();

		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeFinanzsanktionsliste, AufgabenTyp.FINANZSANKTIONSLISTE));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeZertifikat, AufgabenTyp.ZERTIFIKAT));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeAltesZertifikat, AufgabenTyp.ALTES_ZERTIFIKAT));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeAlterAuszug, AufgabenTyp.ALTER_AUSZUG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeSonstige, AufgabenTyp.SONSTIGE));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeAblage, AufgabenTyp.ABLAGE));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeZertifikatsauszug, AufgabenTyp.ZERTIFIKATSAUSZUG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeAbschriftZertifikatsauszug, AufgabenTyp.ABSCHRIFT_ZERTIFIKATSAUSZUG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeRegisterabschrift, AufgabenTyp.REGISTERABSCHRIFT));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeWiedervorlage, AufgabenTyp.WIEDERVORLAGE));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeEintragungsmitteilung, AufgabenTyp.EINTRAGUNGSMITTEILUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeMessbrief, AufgabenTyp.MESSBRIEF));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeRechnungsstellung, AufgabenTyp.RECHNUNGSSTELLUNG));
		this.typeControlsSubscriptions.add(this.buildTypeControlSubscription(this.controlAufgabeKosten, AufgabenTyp.KOSTEN));
	}

	private buildTypeControlSubscription(formControl: UntypedFormControl, aufgabenTyp: AufgabenTyp): Subscription {
		return formControl.valueChanges.subscribe(selected => {
			selected ? this.aufgabeFormService.enableAufgabenTyp(aufgabenTyp) : this.aufgabeFormService.disableAufgabenTyp(aufgabenTyp);
		});
	}

	submit(): boolean {
		if (this.checkPreconditions()) {
			const creating = this.facade.createVerfuegungByVorgang(this.controlVerfuegungNotiz.value);

			if (this.isAnyAufgabeSelected()) {
				creating.pipe(
					filter(created => !created.loading),
					take(1),
				).subscribe(() => {
					this.aufgabeFormService.submit();
					if (this.bescheinigung.enabled) {
						this.createBescheinigungen();
					}
				});
			}
			return true;
		}
		return false;
	}

	private createBescheinigungen() {
		const value = this.bescheinigung.value;
		const body: AufgabeVersandBody = {
			empfaenger: value.empfaenger.control,
			faxEmpfaenger: value.vorabPerFax ? value.faxEmpfaenger.control : [],
			taetigkeit: value.versandTaetigkeit,
		};
		value.bescheinigungsart.control.forEach(typ => {
			this.aufgabeFacade.createAufgabe(<Aufgabe>{
				body,
				notiz: value.notiz,
				typ,
			});
		});
	}

	checkPreconditions(): boolean {
		return (!this.isAnyAufgabeSelected() || this.aufgabeFormService.isValid()) && (this.bescheinigung.disabled || this.bescheinigung.valid);
	}

	private isAnyAufgabeSelected(): boolean {
		return this.controlAufgabeFinanzsanktionsliste.value
			|| this.controlAufgabeZertifikat.value
			|| this.controlAufgabeAltesZertifikat.value
			|| this.controlAufgabeAlterAuszug.value
			|| this.controlAufgabeSonstige.value
			|| this.controlAufgabeAblage.value
			|| this.controlAufgabeZertifikatsauszug.value
			|| this.controlAufgabeAbschriftZertifikatsauszug.value
			|| this.bescheinigung.enabled
			|| this.controlAufgabeRegisterabschrift.value
			|| this.controlAufgabeWiedervorlage.value
			|| this.controlAufgabeEintragungsmitteilung.value
			|| this.controlAufgabeMessbrief.value
			|| this.controlAufgabeRechnungsstellung.value
			|| this.controlAufgabeKosten.value;
	}

	ngOnDestroy(): void { // Todo: SCHIFF-5158 (FormControls aufräumen): Wird gar nicht aufgerufen da in @Injectable
		this.typeControlsSubscriptions.unsubscribe();
	}
}
