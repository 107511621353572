import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../create-aufgabe-dialog/create-aufgabe.formservice';

@Component({
	selector: 'schir-int-client-aufgabe-mitteilung-editor',
	templateUrl: './aufgabe-mitteilung-editor.component.html',
	styleUrls: ['./aufgabe-mitteilung-editor.component.scss'],
})
export class AufgabeMitteilungEditorComponent {

	readonly aufgabenTyp: AufgabenTyp = AufgabenTyp.EINTRAGUNGSMITTEILUNG;
	readonly formServiceClass = CreateAufgabeFormService;
	readonly mitteilungen = EintragungsmitteilungMitteilungen;

	constructor(public formService: CreateAufgabeFormService) { }

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.controlGroupEintragungsmitteilung;
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}

	get eigentuemerNeuGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungEigentuemerNeu];
	}

	get eigentuemerAltGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungEigentuemerAlt];
	}

	get eingetragenerEigentuemerGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungEingetragenerEigentuemer];
	}

	get glaeubigerNeuGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungGlaeubigerNeu];
	}

	get glaeubigerAltGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungGlaeubigerAlt];
	}

	get notarGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungNotar];
	}

	get bshGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungBsh];
	}

	get rechtsanwaelteGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungRechtsanwaelte];
	}
}

export enum EintragungsmitteilungMitteilungen {
	BLATTABSCHRIFT = 'mit Blattabschrift(en)',
	SCHIFFSZERTIFIKAT = 'mit der Bitte das Schiffszertifikat zur Berichtigung einzureichen',
	ZERTIFIKATSAUSZUG = 'mit der Bitte den alten Zertifikatsauszug einzureichen',
	BEGLAUBIGTE_BLATTABSCHRIFT = 'mit beglaubigter Blattabschrift',
	BSH = 'BSH mit dem Zusatz, dass Eintragung im Zertifikat am gleichen Tag erfolgt ist'
}
