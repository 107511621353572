import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';
import {AufgabeModule} from '@schir-int-client/aufgabe';
import {AufgabeSharedModule} from '@schir-int-client/aufgabe-shared';
import {TechModule} from '@schir-int-client/tech';
import {VerfuegungSharedModule} from '@schir-int-client/verfuegung-shared';
import {VerfuegungenInVorgangContainerComponent} from './verfuegungen-in-vorgang-container/verfuegungen-in-vorgang-container.component';
import {CreateVerfuegungDialogComponent} from './verfuegungen-in-vorgang/create-verfuegung-dialog/create-verfuegung-dialog.component';
import {VerfuegungInVorgangComponent} from './verfuegungen-in-vorgang/verfuegung-in-vorgang/verfuegung-in-vorgang.component';
import {VerfuegungenInVorgangComponent} from './verfuegungen-in-vorgang/verfuegungen-in-vorgang.component';
import {CreateKorrespondenzVerfuegungDialogComponent} from './verfuegungen-in-vorgang/create-korrespondenz-verfuegung-dialog/create-korrespondenz-verfuegung-dialog.component';
import {CreateLfprVerfuegungDialogComponent} from './verfuegungen-in-vorgang/create-lfpr-verfuegung-dialog/create-lfpr-verfuegung-dialog.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {BescheinigungEditorComponent} from './verfuegungen-in-vorgang/create-verfuegung-dialog/bescheinigung-editor/bescheinigung-editor.component';

@NgModule({
	declarations: [
		VerfuegungenInVorgangComponent,
		VerfuegungInVorgangComponent,
		VerfuegungenInVorgangContainerComponent,
		CreateVerfuegungDialogComponent,
		CreateLfprVerfuegungDialogComponent,
		CreateKorrespondenzVerfuegungDialogComponent,
		// BescheinigungEditorComponent,
	],
	imports: [
		CommonModule,
		VerfuegungSharedModule,
		AufgabeSharedModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		MatTableModule,
		MatInputModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatSelectModule,
		MatOptionModule,
		MatCheckboxModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		AufgabeModule,
		TechModule,
		MatRadioModule,
		MatDividerModule,
		MatListModule,
		BescheinigungEditorComponent,
	],
	exports: [
		VerfuegungenInVorgangComponent,
		VerfuegungInVorgangComponent,
		CreateVerfuegungDialogComponent,
		CreateLfprVerfuegungDialogComponent,
		CreateKorrespondenzVerfuegungDialogComponent,
	],
})
export class VerfuegungModule {}
