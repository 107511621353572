import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxChange, MatCheckboxModule} from '@angular/material/checkbox';
import {
	AufgabenTyp,
	AufgabeTypLabel,
	AufgabeVersandTaetigkeit,
	aufgabeVersandTaetigkeiten,
	AufgabeVersandTaetigkeitLabel,
} from '@schir-int-client/aufgabe-shared';
import {KontaktKategorie, KontaktKategorieLabel, kontaktKategorien} from '@schir-int-client/adressverwaltung-shared';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {CheckboxesComponent} from '../../../../../../tech/src/lib/checkboxes/checkboxes.component';
import {TechModule} from '@schir-int-client/tech';

@Component({
	selector: 'schir-int-client-bescheinigung-editor',
	templateUrl: './bescheinigung-editor.component.html',
	styleUrls: ['./bescheinigung-editor.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		MatRadioModule,
		MatCheckboxModule,
		ReactiveFormsModule,
		MatInputModule,
		CheckboxesComponent,
		TechModule,
	],
})
export class BescheinigungEditorComponent {
	@Input({ required: true }) formGroup: FormGroup<{
		vorabPerFax: FormControl<boolean>;
		bescheinigungsart: FormGroup<{ control: FormControl<AufgabenTyp[]> }>;
		notiz: FormControl<string>;
		empfaenger: FormGroup<{ control: FormControl<KontaktKategorie[]> }>;
		versandTaetigkeit: FormControl<AufgabeVersandTaetigkeit>;
		faxEmpfaenger: FormGroup<{ control: FormControl<KontaktKategorie[]> }>;
	}>;

	onBescheinigungVorabPerFaxChecked(event: MatCheckboxChange) {
		if (event.checked) {
			this.formGroup.controls.faxEmpfaenger.enable();
		} else {
			this.formGroup.controls.faxEmpfaenger.disable();
		}
	}

	readonly bescheinigungsarten = [
		AufgabenTyp.HYPOTHEKENBESCHEINIGUNG,
		AufgabenTyp.EIGENTUEMERBESCHEINIGUNG,
		AufgabenTyp.LOESCHUNGSBESCHEINIGUNG,
		AufgabenTyp.LASTENFREIHEITSBESCHEINIGUNG,
		AufgabenTyp.NAMENSAENDERUNGSBESCHEINIGUNG,
	];
	readonly aufgabeTypLabel = AufgabeTypLabel;
	readonly kontaktKategorien = kontaktKategorien;
	readonly kontaktKategorieLabel = KontaktKategorieLabel;
	readonly versandTaetigkeiten = aufgabeVersandTaetigkeiten;
	readonly versandTaetigkeitLabel = AufgabeVersandTaetigkeitLabel;
}
