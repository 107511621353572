import {Component} from '@angular/core';
import {AlternativeTextFacade, AlternativeTextResource} from '@schir-int-client/alternative-text-shared';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {Observable} from 'rxjs';
import {AmtlicherAusdruckErstellenDialogFormService} from './amtlicher-ausdruck-erstellen-dialog.formservice';
import {BaseEditorComponent} from '@schir-int-client/tech';
import {filter, take} from 'rxjs/operators';

@Component({
	selector: 'schir-int-client-amtlichen-ausdruck-erstellen-dialog',
	templateUrl: './amtlichen-ausdruck-erstellen-dialog.component.html',
	styleUrls: ['./amtlichen-ausdruck-erstellen-dialog.component.scss'],
})
export class AmtlichenAusdruckErstellenDialogComponent extends BaseEditorComponent {

	alternativeTextList: Observable<StateResource<AlternativeTextResource[]>>;

	constructor(public formService: AmtlicherAusdruckErstellenDialogFormService,
	            private alternativeTextFacade: AlternativeTextFacade) {
		super();

		this.alternativeTextList = this.alternativeTextFacade.getAlternativeTextResources();
	}

	get form() {
		return this.formService.form;
	}

	async submit(): Promise<boolean> {
		// Needs Subscription so that the Observable works.
		this.formService.submit().pipe(filter(done => done.resource), take(1)).subscribe(_ => {});
		return true;
	}

	reset() {
		super.reset();
		this.formService.reset();
	}
}
