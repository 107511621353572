import {formatDate} from '@angular/common';
import {Injectable, OnDestroy} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {getUrl, hasLink} from '@ngxp/rest';
import {AlternativeTextFacade, FlaggenrechtWithAlternativeText} from '@schir-int-client/alternative-text-shared';
import {RegisterBlattFacade, SpaltenName} from '@schir-int-client/register-shared';
import {SignaturFacade} from '@schir-int-client/signatur-shared';
import {StateResource} from '@schir-int-client/ngrx-helpers';
import {isNil} from 'lodash-es';
import {Observable, Subscription} from 'rxjs';
import {stringToDate} from '../../../../../apps/int-client-e2e/src/helpers/date';
import {getRoetenEntry, isRoetenEntry, isTemporaryEntry, isVermerk} from 'libs/register-shared/src/lib/register.util';
import {Context, Language, TranslationService} from '../../../../tech/src/lib/translation/translation.service';
import {map, take} from 'rxjs/operators';
import {ChangeEntryLinkRel, ChangeEntryResource, ChangeEntryVermerkResource} from '@schir-int-client/register-change';

@Injectable({ providedIn: 'root' })
export class AmtlicherAusdruckErstellenDialogFormService implements OnDestroy {
	static readonly DATE_PATTERN = 'dd.mm.yyyy';
	static readonly DATE_REGEX_PATTERN = '\\d{2}\\.\\d{2}\\.\\d{4}';

	static readonly feldOriginalText = 'originalText';
	static readonly feldContainOriginalCheckbox = 'containOriginalText';
	static readonly feldAlternativeTextValue = 'value';
	static readonly alternativeTextResource = 'alternativeTextResource';
	static readonly flaggenrechtResource = 'flaggenrechtResource';

	form: UntypedFormGroup;
	formArray: UntypedFormArray;
	flaggenrechtSubscription: Subscription;
	checkBoxSubscriptions: Subscription;
	alternativeTextSubscriptions: Subscription[] = [];

	constructor(private formBuilder: UntypedFormBuilder,
	            private alternativeTextFacade: AlternativeTextFacade,
	            private registerFacade: RegisterBlattFacade,
	            private signaturFacade: SignaturFacade,
	            private translationService: TranslationService) {
		this.registerFacade.getChangeEntriesByColumn([SpaltenName.FLAGGENRECHT]);

		this.unsubscribe();
		this.initForm();
	}

	initForm() {
		this.formArray = this.formBuilder.array([]);
		this.form = new UntypedFormGroup({ formArray: this.formArray });
		this.flaggenrechtSubscription = this.registerFacade.flaggenrechtEntries.subscribe(changeEntries => {
			this.unsubscribeAlternativeTexts();
			this.formArray.clear();

			for (let changeEntry of changeEntries) {
				const isActiveEntry: boolean = !isTemporaryEntry(changeEntry) && !isRoetenEntry(changeEntry) && !isVermerk(changeEntry);
				const hasSignedRoetung: boolean = !isTemporaryEntry(getRoetenEntry(changeEntries, getUrl(changeEntry)));

				if (isActiveEntry && !hasSignedRoetung && !this.isUnwanted(changeEntry) && !this.isOutdated(changeEntry)) {
					this.createAlternativeTextEntry(changeEntry);
				}
			}
		});
	}

	private isUnwanted(changeEntry: ChangeEntryResource): boolean {
		// TODO: das ist die 2. Kopie, Originalwerte stehen in der DB
		const regex = [
			/^ ?Recht zur Führung der Bundesflagge gemäß §( |&nbsp;)\d( |&nbsp;)Flaggenrechtsgesetz( +erneut +festgestellt)?.?( +[Nn]eu gefasst am .*)?( +[Ee]ingetragen am .*)?. *$/,
			/^ ?Die Genehmigung zur Führung der Flagge von .* auf Zeit ist aufgehoben worden. Recht zur Führung der Bundesflagge gemäß §.*Flaggenrechtsgesetz. *$/,
			/^ ?Verlust des Rechts zur Führung der Bundesflagge. *$/,
			/^ ?Die Genehmigung des Bundesamtes für Seeschifffahrt und Hydrographie zur Führung der Flagge von .* auf Zeit ist aufgehoben worden. *$/,
		];
		const text = changeEntry.spaltenWert.text;
		return typeof text == 'string' && regex.some(r => text.match(r));
	}

	private isOutdated(changeEntry: ChangeEntryResource): boolean {
		const text: string = changeEntry.spaltenWert.text;

		if (text) {
			const endDate: Date = this.extractDate('bis zum ', text);
			const issueDate: Date = new Date();

			issueDate.setHours(0, 0, 0, 0);

			if (endDate != null) {
				return issueDate > endDate;
			}
		}
		return false;
	}

	private createAlternativeTextEntry(changeEntry: ChangeEntryResource): void {
		if (hasLink(changeEntry, ChangeEntryLinkRel.ALTERNATIVE_TEXT)) {
			this.createFormGroupWithAlternativeText(changeEntry);
		} else {
			this.createFormGroupWithoutAlternativeText(changeEntry);
		}
	}

	private createFormGroupWithAlternativeText(changeEntry: ChangeEntryResource): void {
		this.alternativeTextSubscriptions.push(this.alternativeTextFacade.getAlternativeTextByUri(getUrl(changeEntry)).subscribe(alternativeTextResource => {
			if (!isNil(alternativeTextResource)) {
				this.createFormGroup(<FlaggenrechtWithAlternativeText>{
					containOriginalText: alternativeTextResource.containOriginalText,
					value: alternativeTextResource.value,
					originalText: this.getOriginalText(changeEntry),
					alternativeTextResource: alternativeTextResource,
					flaggenrechtResource: changeEntry,
				});
			}
		}));
	}

	private createFormGroupWithoutAlternativeText(changeEntry: ChangeEntryResource): void {
		this.createFormGroup(<FlaggenrechtWithAlternativeText>{
			containOriginalText: true,
			value: null,
			originalText: this.getOriginalText(changeEntry),
			alternativeTextResource: null,
			flaggenrechtResource: changeEntry,
		});
	}

	getOriginalText(changeEntry: ChangeEntryResource): string {
		return isVermerk(changeEntry) ? (<ChangeEntryVermerkResource>changeEntry).spaltenWert.vermerk : changeEntry.spaltenWert.text;
	}

	private createFormGroup(entry: FlaggenrechtWithAlternativeText): void {
		const formGroup: UntypedFormGroup = this.formBuilder.group({
			[AmtlicherAusdruckErstellenDialogFormService.feldContainOriginalCheckbox]: new UntypedFormControl(entry.containOriginalText),
			[AmtlicherAusdruckErstellenDialogFormService.feldAlternativeTextValue]: new UntypedFormControl(entry.value),
			[AmtlicherAusdruckErstellenDialogFormService.feldOriginalText]: new UntypedFormControl(entry.originalText),
			[AmtlicherAusdruckErstellenDialogFormService.alternativeTextResource]: new UntypedFormControl(entry.alternativeTextResource),
			[AmtlicherAusdruckErstellenDialogFormService.flaggenrechtResource]: new UntypedFormControl(entry.flaggenrechtResource),
		});

		this.formArray.push(formGroup);
		this.updateOriginalTextFormControl(formGroup, entry.containOriginalText);
		this.addCheckboxSubscription(formGroup);

	}

	private addCheckboxSubscription(formGroup: UntypedFormGroup) {
		const checkboxSubscription: Subscription = formGroup.controls[AmtlicherAusdruckErstellenDialogFormService.feldContainOriginalCheckbox].valueChanges.subscribe((selected) => {
			this.updateOriginalTextFormControl(formGroup, selected);
		});
		isNil(this.checkBoxSubscriptions) ? this.checkBoxSubscriptions = checkboxSubscription : this.checkBoxSubscriptions.add(checkboxSubscription);
	}

	private updateOriginalTextFormControl(formGroup: UntypedFormGroup, enable: boolean): void {
		const formControl: UntypedFormControl = <UntypedFormControl>formGroup.controls[AmtlicherAusdruckErstellenDialogFormService.feldOriginalText];
		enable ? formControl.enable() : formControl.disable();
	}

	fillAlternativeTextWithTranslatedText(formGroup: UntypedFormGroup) {
		const entryValue: FlaggenrechtWithAlternativeText = formGroup.value;

		this.translate(entryValue.flaggenrechtResource['id']).pipe(take(1)).subscribe((translation) => {
			formGroup.controls[AmtlicherAusdruckErstellenDialogFormService.feldAlternativeTextValue].patchValue(translation);
		});
	}

	translate(changeEntryId: string): Observable<string> {
		return this.translationService.translateAndReplace(Language.EN, Context.FLAGGENRECHT, changeEntryId)
			.pipe(map(translation => translation.text));
	}

	transformDate(date: string | Date) {
		return date ? formatDate(date, 'longDate', 'en-GB') : null;
	}

	submit(): Observable<StateResource<boolean>> {
		return this.alternativeTextFacade.erstellen(this.formArray.value);
	}

	ngOnDestroy(): void {
		this.unsubscribe();
	}

	unsubscribe(): void {
		if (!isNil(this.checkBoxSubscriptions)) {
			this.checkBoxSubscriptions.unsubscribe();
		}
		if (!isNil(this.flaggenrechtSubscription)) {
			this.flaggenrechtSubscription.unsubscribe();
		}

		this.unsubscribeAlternativeTexts();
	}

	private unsubscribeAlternativeTexts() {
		this.alternativeTextSubscriptions.forEach(s => s.unsubscribe());
		this.alternativeTextSubscriptions = [];
	}

	extractDate(prefix: string, text: string): Date {
		const indexOfPrefix = text.indexOf(prefix);

		if ((indexOfPrefix > -1) && (text.length > indexOfPrefix + prefix.length + AmtlicherAusdruckErstellenDialogFormService.DATE_PATTERN.length)) {
			const potentialDateString = text.substring(indexOfPrefix + prefix.length, indexOfPrefix + prefix.length + AmtlicherAusdruckErstellenDialogFormService.DATE_PATTERN.length);

			if (potentialDateString.match(AmtlicherAusdruckErstellenDialogFormService.DATE_REGEX_PATTERN)) {
				const date: Date = stringToDate(potentialDateString);

				date.setHours(0, 0, 0, 0);

				return date;
			}
		}

		return null;
	}

	reset() {
		this.initForm();
	}
}
