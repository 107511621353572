import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {getUrl} from '@ngxp/rest';
import {VerfuegungFacade} from '@schir-int-client/verfuegung-shared';
import {map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {
	AufgabeActions,
	AufgabeMarkAsErledigtAction,
	ClearAdressatenByAufgabeAction,
	ClearSelectedAufgabeAction,
	CreateAufgabeAction,
	CreateAufgabeSuccessAction,
	DeleteAufgabeAction,
	LoadAdressatenByAufgabeAction,
	LoadAufgabenByVerfuegungSuccessAction,
	UpdateAufgabeAction,
	UpdateAufgabeSuccessAction,
} from './aufgabe.actions';
import {AufgabeFacade} from './aufgabe.facade';
import {Aufgabe, AufgabeAnschreibenBody, AufgabeResource} from './aufgabe.model';
import {AufgabeService} from './aufgabe.service';
import {
	CreateKorrespondenzVorlageAction,
	LoadKorrespondenzVorlageAction,
} from '../../../korrespondenz-shared/src/lib/korrespondenz-vorlage/korrespondenz-vorlage.actions';
import {KorrespondenzCreateUserData} from '../../../korrespondenz-shared/src/lib/korrespondenz-vorlage/korrespondenz-vorlage.model';

@Injectable()
export class AufgabeEffects {
	constructor(
		private actions: Actions,
		private aufgabeService: AufgabeService,
		private verfuegungFacade: VerfuegungFacade,
		private aufgabeFacade: AufgabeFacade,
	) { }


	createAufgabe = createEffect(() => this.actions.pipe(
		ofType(AufgabeActions.CREATE_AUFGABE),
		withLatestFrom(this.verfuegungFacade.verfuegung),
		mergeMap(([action, verfuegung]) => {
			const aufgabe: Aufgabe = (<CreateAufgabeAction>action).aufgabe;

			const anschreibenBody: AufgabeAnschreibenBody = <AufgabeAnschreibenBody>aufgabe.body;

			if (anschreibenBody && anschreibenBody.vorlageId) {
				const vorlageId = (<AufgabeAnschreibenBody>aufgabe.body).vorlageId;
				const rechtspfleger = (<AufgabeAnschreibenBody>aufgabe.body).rechtspfleger;
				const datum = (<AufgabeAnschreibenBody>aufgabe.body).datum;

				const request: KorrespondenzCreateUserData = {
					vorlageId: vorlageId,
					rechtspfleger: rechtspfleger,
					datum: datum,
				};

				return this.aufgabeService.createAufgabe(verfuegung, aufgabe).pipe(
					mergeMap(aufgabe => [
						new CreateAufgabeSuccessAction(aufgabe),
						new CreateKorrespondenzVorlageAction(request),
					]),
				);
			} else {
				return this.aufgabeService.createAufgabe(verfuegung, aufgabe).pipe(
					map(aufgabe => new CreateAufgabeSuccessAction(aufgabe)),
				);
			}
		}),
	));


	updateAufgabe = createEffect(() => this.actions.pipe(
		ofType(AufgabeActions.UPDATE_AUFGABE),
		withLatestFrom(this.aufgabeFacade.aufgabe),
		switchMap(([action, aufgabeToUpdate]) => {
			return this.aufgabeService.updateAufgabe(aufgabeToUpdate, (<UpdateAufgabeAction>action).aufgabe).pipe(
				mergeMap(aufgabe => [new UpdateAufgabeSuccessAction(aufgabe), new LoadKorrespondenzVorlageAction(aufgabe)]),
			);
		}),
	));


	loadAufgabenInVerfuegung = createEffect(() => this.actions.pipe(
		ofType(AufgabeActions.LOAD_AUFGABEN_BY_VERFUEGUNG),
		withLatestFrom(this.verfuegungFacade.verfuegung),
		mergeMap(([, verfuegung]) => {
			return this.aufgabeService.getAufgaben(verfuegung).pipe(
				map(aufgabenList => new LoadAufgabenByVerfuegungSuccessAction(verfuegung, aufgabenList)));
		}),
	));


	markAsErledigt = createEffect(() => this.actions.pipe(
		ofType(AufgabeActions.AUFGABE_MARK_AS_ERLEDIGT),
		withLatestFrom(this.verfuegungFacade.verfuegung),
		switchMap(([action, verfuegung]) => {
			const aufgabe: AufgabeResource = (<AufgabeMarkAsErledigtAction>action).aufgabeResource;
			return this.aufgabeService.markAsErledigt(aufgabe).pipe(
				mergeMap(aufgabenList => [new LoadAufgabenByVerfuegungSuccessAction(verfuegung, aufgabenList), new LoadAdressatenByAufgabeAction(aufgabe)]));
		}),
	));


	markAsFreigegeben = createEffect(() => this.actions.pipe(
		ofType(AufgabeActions.AUFGABE_MARK_AS_FREIGEGEBEN),
		withLatestFrom(this.verfuegungFacade.verfuegung),
		switchMap(([action, verfuegung]) => {
			const aufgabe: AufgabeResource = (<AufgabeMarkAsErledigtAction>action).aufgabeResource;
			return this.aufgabeService.markAsFreigegeben(aufgabe).pipe(
				mergeMap(aufgabenList => [new LoadAufgabenByVerfuegungSuccessAction(verfuegung, aufgabenList), new LoadAdressatenByAufgabeAction(aufgabe)]));
		}),
	));


	deleteAufgabe = createEffect(() => this.actions.pipe(
		ofType(AufgabeActions.DELETE_AUFGABE),
		withLatestFrom(this.verfuegungFacade.verfuegung),
		switchMap(([action, verfuegung]) => {
			const aufgabe: AufgabeResource = (<DeleteAufgabeAction>action).aufgabe;
			return this.aufgabeService.deleteAufgabe(aufgabe).pipe(
				mergeMap(aufgabeList => [
					new LoadAufgabenByVerfuegungSuccessAction(verfuegung, aufgabeList),
					new ClearAdressatenByAufgabeAction(getUrl(aufgabe)),
					new ClearSelectedAufgabeAction(),
				]));
		}),
	));
}
