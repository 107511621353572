import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {getUrl, ResourceFactory, ResourceUri} from '@ngxp/rest';
import {KontaktKategorie, KontaktResource} from '@schir-int-client/adressverwaltung-shared';
import {ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {PosteingangLinkRel, PosteingangResource} from '@schir-int-client/posteingang-shared';
import {RegisterName} from '@schir-int-client/register-shared';
import {UserResource} from '@schir-int-client/user-shared';
import {saveAs} from 'file-saver';
import {CONTENT_TYPE__URI_LIST} from '@schir-int-client/tech';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {VerfahrenLinkRel, VerfahrenListLinkRel, ZaehlblattLinkRel} from './verfahren.linkrel';
import {
	NewVerfahren, RegisterAusdruckType,
	VerfahrenListResource,
	VerfahrenResource,
	VerfahrenSearchQuery,
	ZaehlblattAktionen,
	ZaehlblattEntry,
	ZaehlblattResource,
} from './verfahren.model';
import {UnterscheidungssignalResource} from '@schir-int-client/admin-shared';

@Injectable({ providedIn: 'root' })
export class VerfahrenService {

	emptyResource: VerfahrenListResource = {
		aktenzeichen: '',
		amtsgerichtNummer: 0,
		assignTo: '',
		aufschrift: '',
		blattNummer: 0,
		notiz: '',
		posteingangResource: undefined,
		register: undefined,
		status: undefined,
		stammdaten: undefined,
		verfahrenKontakte: [],
		_embedded: {},
		_links: {
			self: {
				href: '',
			},
		},
		page: {
			totalElements: 0,
			size: 0,
		},
	};

	constructor(private resourceFactory: ResourceFactory, private httpClient: HttpClient) { }

	public getAll(rootResource: ApiRootResource): Observable<VerfahrenListResource> {
		try {
			return this.resourceFactory.from(rootResource).get(ApiRootLinkRel.VERFAHRENS);
		} catch (e) {
			return of(this.emptyResource);
		}
	}

	public getOne(verfahrenUri: string): Observable<VerfahrenResource> {
		return this.resourceFactory.fromId(verfahrenUri).get();
	}

	public searchVerfahren(rootResource: ApiRootResource, query: VerfahrenSearchQuery): Observable<VerfahrenListResource> {
		let url = getUrl(rootResource, VerfahrenLinkRel.SEARCH_VERFAHREN)
			.replace('{searchBy}', encodeURIComponent(query.searchString))
			.replace('{searchIn}', query.searchIn.toString())
			.replace('{filterBy}', query.filterByStatus.toString());

		if (query.limit) {
			url = url.replace(/limit=\d+/, 'limit=' + query.limit);
		}

		return this.resourceFactory.fromId(url).get();
	}

	public createVerfahren(posteingangResource: PosteingangResource, register: RegisterName): Observable<VerfahrenListResource> {
		const newVerfahren = {
			register: register,
			amtsgerichtsnummer: undefined,
			blattNummer: undefined,
			eigentuemerName: undefined,
			posteingangResource: undefined,
			schiffsName: undefined,
			status: undefined,
		};
		return this.resourceFactory.from(posteingangResource).post(PosteingangLinkRel.CREATE_VERFAHREN, newVerfahren);
	}

	public createVerfahrenWithoutPosteingang(verfahren: NewVerfahren, verfahrenList: VerfahrenListResource): Observable<VerfahrenListResource> {
		return this.resourceFactory.from(verfahrenList).post(VerfahrenListLinkRel.ADD_VERFAHREN, verfahren);
	}


	public saveRegisterAufschrift(verfahren: VerfahrenResource, aufschrift: string): Observable<VerfahrenResource> {
		return this.resourceFactory.fromId(getUrl(verfahren, VerfahrenLinkRel.AUFSCHRIFT)).patch({ aufschrift });
	}

	public updateNotiz(notiz: string, verfahrenUri: ResourceUri): Observable<VerfahrenResource> {
		return this.resourceFactory.fromId(verfahrenUri).patch({ notiz });
	}

	public markAsAbgelehnt(verfahren: VerfahrenResource): Observable<VerfahrenResource> {
		return this.resourceFactory.from(verfahren).patch(VerfahrenLinkRel.MARK_AS_ABGELEHNT, { status: 'ABGELEHNT' });
	}

	public delete(verfahren: VerfahrenResource): Observable<VerfahrenListResource> {
		return this.resourceFactory.from(verfahren).delete(VerfahrenLinkRel.DELETE);
	}

	public downloadAusdruck(type: RegisterAusdruckType, verfahren: VerfahrenResource) {
		var linkRel;
		switch (type) {
			case 'beglaubigt': linkRel =  VerfahrenLinkRel.BEGLAUBIGTER_REGISTERBLATT_AUSDRUCK; break;
			case 'einfach': linkRel =  VerfahrenLinkRel.REGISTERBLATT_AUSDRUCK; break;
			case 'entwurf': linkRel =  VerfahrenLinkRel.REGISTERBLATT_AUSDRUCK_ENTWURF; break;
			default: throw new Error('Invalid type');
		}
		return this.downloadDocument(getUrl(verfahren, linkRel));
	}

	public downloadAmtlicherAuszug(verfahren: VerfahrenResource) {
		return this.downloadDocument(getUrl(verfahren, VerfahrenLinkRel.REGISTERBLATT_AMTLICHER_AUSZUG));
	}

	public downloadSchiffszertifikat(verfahren: VerfahrenResource) {
		return this.downloadDocument(getUrl(verfahren, VerfahrenLinkRel.REGISTERBLATT_SCHIFFSZERTIFIKAT));
	}

	public downloadSchiffsbrief(verfahren: VerfahrenResource) {
		return this.downloadDocument(getUrl(verfahren, VerfahrenLinkRel.REGISTERBLATT_SCHIFFSBRIEF));
	}

	private downloadDocument(url: string): Observable<Blob> {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/pdf, application/hal+json');

		return this.httpClient.get<Blob>(url, { headers, responseType: 'blob' as 'json' });
	}

	public saveFile(data: Blob, fileName: string) {
		saveAs(data, fileName);
	}

	public verfahrenBearbeiten(verfahren: VerfahrenResource, user: UserResource): Observable<VerfahrenResource> {
		return this.httpClient.patch<VerfahrenResource>(getUrl(verfahren, VerfahrenLinkRel.BEARBEITEN), getUrl(user), { headers: CONTENT_TYPE__URI_LIST });
	}

	public verfahrenUebernehmen(verfahren: VerfahrenResource, user: UserResource): Observable<VerfahrenResource> {
		return this.httpClient.patch<VerfahrenResource>(getUrl(verfahren, VerfahrenLinkRel.UEBERNEHMEN), getUrl(user), { headers: CONTENT_TYPE__URI_LIST });
	}

	public verfahrenBearbeitenBeenden(verfahren: VerfahrenResource): Observable<VerfahrenResource> {
		return this.httpClient.delete<VerfahrenResource>(getUrl(verfahren, VerfahrenLinkRel.BEARBEITEN_BEENDEN));
	}

	public unassignKontakt(verfahren: VerfahrenResource, kontakt: KontaktResource, kategorie: KontaktKategorie): Observable<VerfahrenResource> {
		const kontaktId: string = kontakt._links.self.href.substring(kontakt._links.self.href.lastIndexOf('/') + 1);
		const url: string = getUrl(verfahren, VerfahrenLinkRel.UNASSIGN_KONTAKT_IN_KATEGORIE).replace('{kontaktId}', kontaktId);
		const httpOptions = {
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(kategorie),
		};
		return this.httpClient.delete<VerfahrenResource>(url, httpOptions).pipe(
			map(httpEvent => httpEvent as unknown as VerfahrenResource),
		);
	}

	public assignUsignal(verfahren: VerfahrenResource): Observable<HttpResponse<UnterscheidungssignalResource>> {
		return this.httpClient.patch<UnterscheidungssignalResource>(getUrl(verfahren, VerfahrenLinkRel.ASSIGN_USIGNAL), {}, { observe: 'response' });
	}

	setVerfahrenProperty(verfahren: VerfahrenResource, linkRel: string, value: string): Observable<VerfahrenResource> {
		return this.httpClient.patch<VerfahrenResource>(getUrl(verfahren, linkRel), value);
	}

	addZaehlblattAktionen(values: ZaehlblattAktionen, zaehlblatt: ZaehlblattResource): Observable<ZaehlblattResource> {
		return this.httpClient.post<ZaehlblattResource>(getUrl(zaehlblatt, ZaehlblattLinkRel.ADD_AKTIONEN), values);
	}

	deleteZaehlblattAktion(entry: ZaehlblattEntry): Observable<ZaehlblattResource> {
		return this.httpClient.delete<ZaehlblattResource>(getUrl(entry, ZaehlblattLinkRel.DELETE_AKTION));
	}

	loadZaehlblatt(verfahren: VerfahrenResource): Observable<ZaehlblattResource> {
		return this.httpClient.get<ZaehlblattResource>(getUrl(verfahren, VerfahrenLinkRel.ZAEHLBLATT));
	}

	exportVerfahren(url: string): Observable<Blob> {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/hal+json');
		return this.httpClient.get<Blob>(url, { headers, responseType: 'blob' as 'json' });
	}
}
