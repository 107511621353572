import {Resource, ResourceUri} from '@ngxp/rest';
import {KontaktKategorie, KontaktResource} from '@schir-int-client/adressverwaltung-shared';

export enum AufgabenTyp {
	FINANZSANKTIONSLISTE = 'FINANZSANKTIONSLISTE',
	ZERTIFIKAT = 'ZERTIFIKAT',
	ALTES_ZERTIFIKAT = 'ALTES_ZERTIFIKAT',
	ZERTIFIKATSAUSZUG = 'ZERTIFIKATSAUSZUG',
	ABSCHRIFT_ZERTIFIKATSAUSZUG = 'ABSCHRIFT_ZERTIFIKATSAUSZUG',
	ALTER_AUSZUG = 'ALTER_AUSZUG',
	HYPOTHEKENBESCHEINIGUNG = 'HYPOTHEKENBESCHEINIGUNG',
	EIGENTUEMERBESCHEINIGUNG = 'EIGENTUEMERBESCHEINIGUNG',
	LOESCHUNGSBESCHEINIGUNG = 'LOESCHUNGSBESCHEINIGUNG',
	LASTENFREIHEITSBESCHEINIGUNG = 'LASTENFREIHEITSBESCHEINIGUNG',
	NAMENSAENDERUNGSBESCHEINIGUNG = 'NAMENSAENDERUNGSBESCHEINIGUNG',
	REGISTERABSCHRIFT = 'REGISTERABSCHRIFT',
	EINTRAGUNGSMITTEILUNG = 'EINTRAGUNGSMITTEILUNG',
	MESSBRIEF = 'MESSBRIEF',
	SONSTIGE = 'SONSTIGE',
	RECHNUNGSSTELLUNG = 'RECHNUNGSSTELLUNG',
	KOSTEN = 'KOSTEN',
	WIEDERVORLAGE = 'WIEDERVORLAGE',
	ABLAGE = 'ABLAGE',
	ANSCHREIBEN = 'ANSCHREIBEN',
	LFPR_REGISTERAKTE_ANLEGEN = 'LFPR_REGISTERAKTE_ANLEGEN',
	LFPR_SCHULDTITEL = 'LFPR_SCHULDTITEL',
	LFPR_MITTEILUNG = 'LFPR_MITTEILUNG',
	LFPR_STATISTIK = 'LFPR_STATISTIK',
	LFPR_VERWEISUNGSVERMERK = 'LFPR_VERWEISUNGSVERMERK',
	LFPR_DB_AKTUALISIEREN = 'LFPR_DB_AKTUALISIEREN',
	LFPR_BLATTABSCHRIFT = 'LFPR_BLATTABSCHRIFT',
	LFPR_NEGATIVBESCHEINIGUNG = 'LFPR_NEGATIVBESCHEINIGUNG',
	LFPR_KOSTEN = 'LFPR_KOSTEN',
	LFPR_WIEDERVORLAGE = 'LFPR_WIEDERVORLAGE',
	LFPR_ERLEDIGT = 'LFPR_ERLEDIGT',
	LFPR_WEGLEGEN = 'LFPR_WEGLEGEN',
	LFPR_SAMMLUNG = 'LFPR_SAMMLUNG'
}

export enum AufgabeStatus {
	NEU = 'NEU',
	FREIGEGEBEN = 'FREIGEGEBEN',
	ERLEDIGT = 'ERLEDIGT'
}

export enum AufgabeZertifikatTaetigkeit {
	FERTIGEN = 'FERTIGEN',
	BERICHTIGEN = 'BERICHTIGEN',
	NACH_EINGANG_BERICHTIGEN = 'NACH_EINGANG_BERICHTIGEN'
}

export enum AufgabeVersandTaetigkeit {
	SENDEN_AN = 'SENDEN_AN',
	AUSHAENDIGEN_AN = 'AUSHAENDIGEN_AN'
}

export const aufgabeVersandTaetigkeiten = [AufgabeVersandTaetigkeit.SENDEN_AN, AufgabeVersandTaetigkeit.AUSHAENDIGEN_AN];

export enum AufgabeLfprKostenTaetigkeit {
	GEZAHLT = 'GEZAHLT',
	ERFORDERN = 'ERFORDERN',
	SOLL = 'SOLL',
	FREI = 'FREI'
}

export enum AufgabeRechnungsstellungErledigung {
	ERLEDIGT = 'ERLEDIGT',
	SPAETER = 'SPAETER'
}

export interface Aufgabe {
	typ: AufgabenTyp;
	status: AufgabeStatus;
	notiz: string;
	adressaten: AdressatWithResource[];
	body: AufgabeZertifikatBody | AufgabeVersandBody | AufgabeAbschriftBody | AufgabeWiedervorlageBody | AufgabeRechnungsstellungBody | AufgabeMitteilungBody | AufgabeAnschreibenBody;
	korrespondenzVorlageId: ResourceUri;
}

export interface AufgabeZertifikatBody {
	taetigkeit: AufgabeZertifikatTaetigkeit;
	versandTaetigkeit: AufgabeVersandTaetigkeit;
	sendenAn: KontaktKategorie[];
}

export interface AufgabeVersandBody {
	taetigkeit: AufgabeVersandTaetigkeit;
	empfaenger: KontaktKategorie[];
	faxEmpfaenger: KontaktKategorie[];
}

export interface AufgabeAbschriftBody {
	beglaubigte: number;
	einfache: number;
}

export interface AufgabeWiedervorlageBody {
	wiedervorlageDatum: Date;
}

export interface AufgabeRechnungsstellungBody {
	erledigung: AufgabeRechnungsstellungErledigung;
}

export interface AufgabeMitteilungBody {
	anEigentuemerNeuSenden: boolean;
	eigentuemerNeuMitteilung: AnEigentuemerZuSenden;

	anEigentuemerAltSenden: boolean;
	eigentuemerAltMitteilung: AnEigentuemerZuSenden;

	anEingetragenerEigentuemerSenden: boolean;
	eingetragenerEigentuemerMitteilung: AnEigentuemerZuSenden;

	anGlaeubigerNeuSenden: boolean;
	glaeubigerNeuMitteilung: AnGlaeubigerNeuZuSenden;

	anGlaeubigerAltSenden: boolean;
	glaeubigerAltMitteilung: AnGlaeubigerAltZuSenden;

	anNotarSenden: boolean;
	notarMitteilung: AnNotarZuSenden;

	anBehoerdenSenden: boolean;

	anBshSenden: boolean;
	bshMitteilung: AnBshZuSenden;

	anRechtsanwaelteSenden: boolean;
	rechtsanwaelteMitteilung: AnRechtsanwaelteZuSenden;
}

export interface AufgabeAnschreibenBody {
	vorlageId: string;
	rechtspfleger: string;
	datum: Date;
}

export interface AnEigentuemerZuSenden {
	blattabschrift: boolean;
	zertifikatsBerichtigung: boolean;
	auszugsEinreichung: boolean;
}

export interface AnGlaeubigerNeuZuSenden {
	blattabschrift: boolean;
	zertifikatsBerichtigung: boolean;
}

export interface AnGlaeubigerAltZuSenden {
	blattabschrift: boolean;
	zertifikatsBerichtigung: boolean;
}

export interface AnNotarZuSenden {
	blattabschrift: boolean;
}

export interface AnBshZuSenden {
	blattabschrift: boolean;
}

export interface AnRechtsanwaelteZuSenden {
	blattabschrift: boolean;
	zertifikatsBerichtigung: boolean;
}

export interface AufgabeResource extends Aufgabe, Resource {}

export interface AufgabeListResource extends Resource {}

export interface Adressat {
	kontakt: ResourceUri;
	kategorie: KontaktKategorie;
	channelBrief: boolean;
	channelFax: boolean;
	channelEmail: boolean;
	channelAbholung: boolean;
	channelEgvp: boolean;
	notiz: string;
}

export interface AdressatDragData {
	uri: ResourceUri;
	name: string;
	vorname: string;
	firmenName: string;
	kategorie: string;
}

export interface WithResource {
	resource: KontaktResource;
}

export interface AdressatWithResource extends AdressatResource, WithResource {}

export interface AdressatResource extends Adressat, Resource {}

export interface AdressatListResource extends Resource {}
