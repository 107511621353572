<div class="focus-inwards-dark">
	<li class="c-list__item" [ngClass]="expand? 'c-list__item--expanded' : ''"
	    [attr.data-test-id]="'adressat-in-aufgabe-root ' + (adressat | testIdFromResource)">
		<a class="c-list__link" (click)="expand = !expand" (keyup.enter)="expand = !expand" tabindex="0"
		   data-test-id="expand-decrease">
			<div class="c-row c-row--header">
				<span class="c-row__item c-row__item--header"
				      data-test-id="vorname-name">{{ adressat.resource | nameByKontaktPipe }}</span>
				<span class="c-row__item c-row__item--secondary"
				      data-test-id="kanal">{{ adressat | channelByAdressat }}</span>
			</div>
			<div class="c-row">
				<span class="c-row__item" data-test-id="firmenName1">{{ adressat.resource.firmenName1 }}</span>
			</div>
			<div class="c-row">
				<span class="c-row__item" data-test-id="firmenName2">{{ adressat.resource.firmenName2 }}</span>
			</div>
			<div class="c-row mt-1">
			<span class="c-row__item"
			      data-test-id="kategorie">{{ adressat.kategorie | enumToLabel: kontaktKategorieLabel }}</span>
			</div>
			<div class="c-row c-row--secondary">
				<span class="c-row__item" data-test-id="notiz">{{ adressat.notiz }}</span>
			</div>
			<i class="material-icons c-list__icon"
			   aria-hidden="true">{{ expand ? 'arrow_drop_up' : 'arrow_drop_down' }}</i>
		</a>
		<div class="expandable">
			<p data-test-id="strasse-hausnummer">{{ adressat.resource.strasse }} {{ adressat.resource.hausnummer }}</p>
			<p data-test-id="plz-stadt">{{ adressat.resource.plz }} {{ adressat.resource.stadt }}</p>
			<br>
			<p data-test-id="telefon" *ngIf='adressat.resource.telefon'>Telefon: {{ adressat.resource.telefon }}</p>
			<p data-test-id="email" *ngIf='adressat.resource.email'>E-Mail: {{ adressat.resource.email }}</p>
			<p data-test-id="fax" *ngIf='adressat.resource.fax'>Fax: {{ adressat.resource.fax }}</p>
		</div>
		<span class="visually-hidden" role="alert">{{ audioAlert | async }}</span>
		<div class="c-button-bar c-button-bar--overlay">
			<schir-int-client-button-round *ngIf="adressat | hasResourceLink: linkRel.NOTIZ"
			                               data-test-id="open-notiz-dialog-button" (click)="openNotizDialog()"
			                               popup="dialog" toolTip="Notiz bearbeiten" materialIcon="notes"
			                               cssClass="focus-inwards-dark schir-midi-button">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="adressat | hasResourceLink: linkRel.DOWNLOAD_KORRESPONDENZ"
			                               fontelloIcon="korrespondenz" data-test-id="download-korrespondenz-button"
			                               (click)="downloadKorrespondenz()"
			                               toolTip="Korrespondenz downloaden"
			                               cssClass="focus-inwards-dark schir-midi-button">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="adressat | hasResourceLink: linkRel.NOTIZ"
			                               data-test-id="edit-button" toolTip="Versandart wählen" materialIcon="edit"
			                               (click)="edit()" cssClass="focus-inwards-dark schir-midi-button">
			</schir-int-client-button-round>
			<schir-int-client-button-round *ngIf="adressat | hasResourceLink: linkRel.DELETE" materialIcon="delete"
			                               data-test-id="delete-kontakt-button" toolTip="Adressat aus Aufgabe löschen"
			                               (click)="delete()"
			                               cssClass="focus-inwards-dark schir-midi-button">
			</schir-int-client-button-round>
		</div>
	</li>
</div>
