import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
	AdressverwaltungFacade,
	KontaktAnrede,
	KontaktAnredeLabel,
	KontaktLinkRel,
	KontaktResource,
} from '@schir-int-client/adressverwaltung-shared';
import {debounce, isEqual, isNil} from 'lodash-es';
import {KontaktFormService} from './kontakt.formservice';
import {
	AccessibilityService,
	DialogService,
	EnumToLabelPipe,
	PotentiallyDirty,
	Selectable,
} from '@schir-int-client/tech';
import {Subscription} from 'rxjs';
import {VerfahrenFacade, VerfahrenLinkRel, VerfahrenResource} from '@schir-int-client/verfahren-shared';
import {getEmbeddedResource} from '@ngxp/rest';
import {filter, take} from 'rxjs/operators';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {DialogDataYesNo} from '@schir-int-client/dialog-shared';
import {
	AktenzeichenRenderer,
	AktenzeichenRendererProvider,
	AktenzeichenUtilService,
} from '@schir-int-client/aktenzeichen-shared';
import {MatDialog} from '@angular/material/dialog';

@Component({
	selector: 'schir-int-client-adressverwaltung-kontakt-view',
	templateUrl: './adressverwaltung-kontakt-view.component.html',
	styleUrls: ['./adressverwaltung-kontakt-view.component.scss'],
})
export class AdressverwaltungKontaktViewComponent implements PotentiallyDirty, OnDestroy, AfterViewInit {

	@Input() selectedKontakt: KontaktResource;
	@Input() editMode: boolean;

	@Output() onSubmit: EventEmitter<any> = new EventEmitter();

	linkRel = KontaktLinkRel;
	idPrefix: string;
	aehnlicherKontakt: boolean;

	private subscriptions: Subscription[] = [];

	selectedAktenzeichen: string;
	behoerdenAutoKontaktSelectionList: string[];

	private readonly MSG_ACTIVATE = 'Wollen Sie diesen Kontakt wirklich aktivieren?';
	private readonly MSG_INACTIVATE = 'Wollen Sie diesen Kontakt wirklich inaktivieren?';
	private readonly MSG_MULTIPLE = 'Der Kontakt ist folgenden Verfahren zugeordnet. Trotzdem inaktivieren?';
	readonly MSG_DUPLICATE_JUR = 'Es gibt bereits einen juristischen Kontakt mit dem Firmennamen.';
	readonly MSG_DUPLICATE_NAT = 'Es gibt bereits einen Kontakt mit demselben Vornamen und Namen.';

	private aktenzeichenRenderer: AktenzeichenRenderer;

	anredeValues: Selectable[] = [{ label: KontaktAnredeLabel.HERR, value: KontaktAnrede.HERR }, {
		label: KontaktAnredeLabel.FRAU,
		value: KontaktAnrede.FRAU,
	}];

	juristischeAnredeValues: Selectable[] = [
		{ label: KontaktAnredeLabel.EMPTY, value: KontaktAnredeLabel.EMPTY },
		{ label: KontaktAnredeLabel.HERR, value: KontaktAnrede.HERR },
		{ label: KontaktAnredeLabel.FRAU, value: KontaktAnrede.FRAU },
	];

	readonly debouncedCheckForSimilarContact = debounce(this.checkForSimilarContact, 500, { leading: true });

	constructor(private formService: KontaktFormService,
	            private facade: AdressverwaltungFacade,
	            private accessibilityService: AccessibilityService,
	            private dialogService: DialogService,
	            private matDialog: MatDialog,
	            private verfahrenFacade: VerfahrenFacade,
	            private enumToLabelPipe: EnumToLabelPipe,
	            private apiRootFacade: ApiRootFacade,
	            private aktenzeichenUtil: AktenzeichenUtilService,
	            private aktenzeichenRendererProvider: AktenzeichenRendererProvider) {
		this.idPrefix = 'i' + Math.random().toString(36).substr(2, 9) + '_'; // good enough
		this.subscriptions.push(this.verfahrenFacade.selectedVerfahren.subscribe(verfahren => {
			if (!isNil(verfahren)) {
				this.selectedAktenzeichen = verfahren.aktenzeichen;
			}
		}));
		this.subscriptions.push(this.apiRootFacade.getCurrentApiRoot().pipe(filter(apiRoot => !isNil(apiRoot))).subscribe(apiRoot => {
			this.behoerdenAutoKontaktSelectionList = apiRoot.features.behoerdeAutoKontakt;
		}));

		this.aktenzeichenRenderer = aktenzeichenRendererProvider.getRenderer();
	}

	ngAfterViewInit() {
		this.subscriptions.push(this.facade.editMode.subscribe(editMode => {
			if (editMode) {
				setTimeout(() => {
					this.accessibilityService.focusElementById('jurCheckbox');
				});
			}
		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	get form(): UntypedFormGroup {
		return this.formService.form;
	}

	getFormControl(controlName: string): UntypedFormControl {
		const ctrl = <UntypedFormControl>this.form.controls[controlName];
		ctrl.enable();
		return ctrl;
	}

	isFirmendatenEmpty() {
		let c = this.form.controls;
		return (c['firmenName'].value == null && c['firmenName1'].value == null
			&& c['firmenName2'].value == null && c['sitz'].value == null
			&& c['zustaendigesAmtsgericht'].value == null && c['handelsregisterNummer'].value == null);
	}

	isPersonendatenEmpty() {
		let c = this.form.controls;
		return (c['anrede'].value == null && c['titel'].value == null
			&& c['vorname'].value == null && c['name'].value == null
			&& c['geburtsname'].value == null && c['namenszusatz'].value == null
			&& c['weitereVornamen'].value == null && c['geburtsDatum'].value == null);
	}

	isKontaktdatenEmpty() {
		let c = this.form.controls;
		return (c['strasse'].value == null && c['hausnummer'].value == null
			&& c['adresszusatz'].value == null && c['plz'].value == null
			&& c['stadt'].value == null && c['land'].value == null
			&& c['postfach'].value == null && c['telefon'].value == null
			&& c['email'].value == null);
	}

	isSonstigedatenEmpty() {
		let c = this.form.controls;
		return (c['telefon2'].value == null && c['telefon3'].value == null
			&& c['fax'].value == null && c['egvpAdresse'].value == null
			&& c['deMail'].value == null && c['notiz'].value == null);
	}

	private checkForSimilarContact() {
		this.facade.getKontakte(null).forEach((res) => {
			this.aehnlicherKontakt = res?.some((kontakt: KontaktResource) => {
				if (isEqual(kontakt, this.selectedKontakt)) {
					return false;
				}
				if (this.form.controls['juristischePerson'].value) {
					return kontakt.juristischePerson
						&& kontakt.firmenName?.toString().toLowerCase() == this.form.controls['firmenName'].value?.toString().toLowerCase();
				}
				return !kontakt.juristischePerson
					&& kontakt.vorname?.toString().toLowerCase() == this.form.controls['vorname'].value?.toString().toLowerCase()
					&& kontakt.name?.toString().toLowerCase() == this.form.controls['name'].value?.toString().toLowerCase();
			});
		});
	}

	edit(): void {
		this.aehnlicherKontakt = false;
		this.formService.activateEditMode();
	}

	submit(): void {
		if (this.formService.submit()) {
			this.subscriptions.push(this.facade.selectedKontakt.pipe(filter(kontakt => Boolean(kontakt.title)), take(1)).subscribe(kontakt => {
				this.formService.kontaktResource = kontakt;
				this.openKontaktAssignMenu();
			}));
			this.formService.deactivateEditMode();
			this.formService.form.markAsPristine();
		}
	}

	private openKontaktAssignMenu() {
		this.facade.renderedKontakt.pipe(take(1)).subscribe(kontaktDiv => {
			kontaktDiv.nativeElement.querySelector('button').click();
		});
	}

	reset() {
		// nothing to do
	}

	isValid(): boolean {
		return this.form.valid;
	}

	isDirty(): boolean {
		return this.form.dirty;
	}

	inactivate() {
		this.subscriptions.push(this.facade.getAllVerfahrenForKontakt(this.selectedKontakt).subscribe(vList => {
				const verfahrenList: VerfahrenResource[] = getEmbeddedResource(vList, VerfahrenLinkRel.VERFAHREN_LIST);

				if (verfahrenList) {
					if (this.belongsToOtherVerfahren(verfahrenList)) {
						const additionalContent = [];

						verfahrenList.forEach(verfahren => {
							const aktenzeichen = this.aktenzeichenUtil.parse(verfahren.aktenzeichen);
							additionalContent.push(this.aktenzeichenRenderer.render(aktenzeichen));
						});

						this.showDialog(additionalContent);
					} else {
						this.showDialog([]);
					}
				} else {
					this.showDialog([]);
				}
			},
		));
	}

	private belongsToOtherVerfahren(verfahrenList: VerfahrenResource[]) {
		return verfahrenList.find(verfahren => verfahren.aktenzeichen !== this.selectedAktenzeichen);
	}

	private showDialog(additionalContent: string[]) {
		let dialogYesNo = this.dialogService.openDialogYesNo(additionalContent.length > 0 ? this.MSG_MULTIPLE : this.MSG_INACTIVATE, ['Inaktivieren', 'Abbrechen'], [], additionalContent);
		this.subscriptions.push(dialogYesNo.pipe(take(1)).subscribe((dialog: DialogDataYesNo) => {
			if (dialog && dialog.agree) {
				const updatedKontakt = { ...this.selectedKontakt, active: false };
				this.facade.updateKontakt(updatedKontakt);
				this.formService.form.reset();

				if (dialog.keyboard) {
					this.focus('kontakt-search-input');
				}
			}
		}));
	}

	activate() {
		let dialogYesNo = this.dialogService.openDialogYesNo(this.MSG_ACTIVATE, ['Aktivieren', 'Abbrechen']);
		this.subscriptions.push(dialogYesNo.pipe(take(1)).subscribe((dialog: DialogDataYesNo) => {
			if (dialog && dialog.agree) {
				const updatedKontakt = { ...this.selectedKontakt, active: true };
				this.facade.updateKontakt(updatedKontakt);

				if (dialog.keyboard) {
					this.focus('edit-button');
				}
			}
		}));
	}

	private focus(elementId: string) {
		setTimeout(() => {
			this.accessibilityService.focusElementById(elementId);
		});
	}

	inactiveAndSelected(): boolean {
		return !this.selectedKontakt.active && (!this.isPersonendatenEmpty() || !this.isFirmendatenEmpty());
	}

	fillSitzIfNeededAndEmpty(): boolean {
		if (this.editMode) {
			const stadt = this.getFormControl('stadt').value;
			if (!isNil(stadt)) {
				const sitzCtrl = this.getFormControl('sitz');
				if (isNil(sitzCtrl.value)) {
					sitzCtrl.patchValue(stadt);
				}
			}
		}
		return true;
	}
}
