import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ResourceFactory, ResourceUri} from '@ngxp/rest';
import {ApiRootLinkRel, ApiRootResource} from '@schir-int-client/api-root';
import {BinaryFileResource} from '@schir-int-client/binary-shared';
import {AufgabeLinkRel, AufgabeResource} from '@schir-int-client/aufgabe-shared';
import {Observable} from 'rxjs';
import {VorlageLinkRel} from './vorlage.linkrel';
import {Vorlage, VorlageListResource, VorlageResource} from './vorlage.model';

@Injectable({ providedIn: 'root' })
export class VorlageService {
	public static readonly BENUTZERDEFINIERTE_VORLAGE_ID = '-1';

	constructor(private resourceFactory: ResourceFactory, private httpClient: HttpClient) { }

	public getAll(resource: ApiRootResource): Observable<VorlageListResource> {
		return this.resourceFactory.from(resource).get(ApiRootLinkRel.VORLAGES);
	}

	public saveOne(resource: ApiRootResource, vorlage: Vorlage): Observable<VorlageResource> {
		return this.resourceFactory.from(resource).post(ApiRootLinkRel.VORLAGES, vorlage);
	}

	public loadBinaryFile(uri: ResourceUri): Observable<BinaryFileResource> {
		return this.resourceFactory.fromId(uri).get();
	}

	public deleteOne(resource: VorlageResource): Observable<{}> {
		return this.resourceFactory.from(resource).delete(VorlageLinkRel.DELETE);
	}

	public getByAufgabe(aufgabe: AufgabeResource): Observable<VorlageResource> {
		return this.resourceFactory.from(aufgabe).get(AufgabeLinkRel.VORLAGE);
	}
}
