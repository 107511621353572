import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AufgabenTyp, AufgabeVersandTaetigkeit} from '@schir-int-client/aufgabe-shared';
import {CreateAufgabeFormService} from '../../create-aufgabe-dialog/create-aufgabe.formservice';
import {VorlageResource} from '@schir-int-client/vorlage-shared';
import {getUrl} from '@ngxp/rest';
import {KorrespondenzValidationMessages} from '../../korrespondenz.messages';

@Component({
	selector: 'schir-int-client-aufgabe-anschreiben-editor',
	templateUrl: './aufgabe-anschreiben-editor.component.html',
	styleUrls: ['./aufgabe-anschreiben-editor.component.scss'],
})
export class AufgabeAnschreibenEditorComponent {
	@Input() vorlagen: VorlageResource[];

	readonly aufgabenTyp = AufgabenTyp.ANSCHREIBEN;
	readonly versandTaetigkeit = AufgabeVersandTaetigkeit;
	readonly formServiceClass = CreateAufgabeFormService;

	readonly messages = KorrespondenzValidationMessages;

	constructor(private formService: CreateAufgabeFormService) {}

	get formGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formService.form.controls[this.aufgabenTyp];
	}

	get bodyGroup(): UntypedFormGroup {
		return <UntypedFormGroup>this.formGroup.controls[CreateAufgabeFormService.feldAufgabeBody];
	}

	get hasFax(): boolean {
		return this.formService.hasFax(this.aufgabenTyp);
	}

	getVorlageUrl(vorlage: VorlageResource) {
		return getUrl(vorlage);
	}
}
