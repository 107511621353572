import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Inject,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import {
	AdressverwaltungFacade,
	KontaktKategorie,
	KontaktKategorieLabel,
	KontaktResource,
} from '@schir-int-client/adressverwaltung-shared';
import {Observable, Subject, Subscription} from 'rxjs';
import {VerfahrenFacade} from '@schir-int-client/verfahren-shared';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {isNil} from 'lodash-es';
import {filter} from 'rxjs/operators';
import {AktenzeichenRendererProvider, AktenzeichenUtilService} from '@schir-int-client/aktenzeichen-shared';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'schir-int-client-kontakt-assign-menu',
	templateUrl: './kontakt-assign-menu.component.html',
	styleUrls: ['./kontakt-assign-menu.component.scss'],
})
export class KontaktAssignMenuComponent implements OnDestroy, OnInit, AfterViewInit {

	@ViewChild('assignMenuRoot') root: ElementRef;

	public matDialogRef: MatDialogRef<KontaktAssignMenuComponent, any>;

	selectedKontakt: KontaktResource;

	readonly kontaktKategorie = KontaktKategorie;
	readonly kontaktKategorieLabel = KontaktKategorieLabel;

	kontakte: Observable<KontaktResource[]>;
	selectedVerfahrenAktenzeichen: string;

	form: UntypedFormGroup;

	assignedAs: { [kategorie: string]: boolean } = {};

	private subscriptions: Subscription[] = [];
	private inside: boolean;

	private changed = false;
	audioAlert: Subject<string> = new Subject<string>();

	protected title = '';

	constructor(@Inject(MAT_DIALOG_DATA) kontakt: KontaktResource,
	            private formBuilder: UntypedFormBuilder,
	            public adressverwaltungFacade: AdressverwaltungFacade,
	            private verfahrenFacade: VerfahrenFacade,
	            private aktenzeichenUtilService: AktenzeichenUtilService,
	            private rendererProvider: AktenzeichenRendererProvider,
	            private renderer: Renderer2,
	) {
		this.selectedKontakt = kontakt;
		this.form = this.formBuilder.group({
			eigentuemer_neu: false,
			eigentuemer_alt: false,
			vertreter: false,
			glaeubiger_neu: false,
			glaeubiger_alt: false,
			notar: false,
			behoerden: false,
			sonstige: false,
			rechtsanwalt: false,
			phg: false,
			gesellschafter: false,
			eingetragener_eigentuemer: false,
		});
	}

	getFormControl(controlName: string): UntypedFormControl {
		return <UntypedFormControl>this.form.controls[controlName];
	}

	ngOnInit() {
		this.createUpdateSubscription(this.adressverwaltungFacade.eigentuemerNeuKontakte, 'eigentuemer_neu');
		this.createUpdateSubscription(this.adressverwaltungFacade.eigentuemerAltKontakte, 'eigentuemer_alt');
		this.createUpdateSubscription(this.adressverwaltungFacade.vertreterKontakte, 'vertreter');
		this.createUpdateSubscription(this.adressverwaltungFacade.glaeubigerNeuKontakte, 'glaeubiger_neu');
		this.createUpdateSubscription(this.adressverwaltungFacade.glaeubigerAltKontakte, 'glaeubiger_alt');
		this.createUpdateSubscription(this.adressverwaltungFacade.notarKontakte, 'notar');
		this.createUpdateSubscription(this.adressverwaltungFacade.rechtsanwaltKontakte, 'rechtsanwalt');
		this.createUpdateSubscription(this.adressverwaltungFacade.behoerdenKontakte, 'behoerden');
		this.createUpdateSubscription(this.adressverwaltungFacade.sonstigeKontakte, 'sonstige');
		this.createUpdateSubscription(this.adressverwaltungFacade.phgKontakte, 'phg');
		this.createUpdateSubscription(this.adressverwaltungFacade.gesellschafterKontakte, 'gesellschafter');
		this.createUpdateSubscription(this.adressverwaltungFacade.eingetragenerEigentuemerKontakte, 'eingetragener_eigentuemer');
		this.subscriptions.push(this.verfahrenFacade.selectedVerfahren.pipe(filter(verfahren => !isNil(verfahren)))
			.subscribe(verfahren => {
				const aktenzeichen = this.aktenzeichenUtilService.parse(verfahren);
				this.selectedVerfahrenAktenzeichen = this.rendererProvider.getRenderer().render(aktenzeichen);
			}));
	}

	ngAfterViewInit() {
		const nativeElement = this.root.nativeElement.parentElement.parentElement;
		const bottom = nativeElement.getBoundingClientRect().bottom;
		if (window.innerHeight < bottom + 10) {
			this.renderer.setStyle(nativeElement, 'bottom', '10px');
		}
	}

	ngAfterContentInit(){
		this.title = this.formatTitle();
	}

	onValueChange(kontaktKategorie) {
		if (this.form.controls[kontaktKategorie.toLowerCase()].value) {
			this.adressverwaltungFacade.assignKontakt(kontaktKategorie);
		} else {
			this.verfahrenFacade.unassignKontakt({ kontakt: this.selectedKontakt, kategorie: kontaktKategorie });
		}
		this.changed = true;
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	@HostListener('click')
	clickedInside() {
		this.inside = true;
	}

	@HostListener('document:click')
	clickedOutside() {
		if (this.inside != null && !this.inside) {
			if (this.changed) {
				this.audioAlert.next('Änderungen in der Rollenzuordnung des Kontakts wurden gespeichert.');
			}
			this.matDialogRef.close();
		}
		this.inside = false;
	}

	updateAssignedValue(kontakte: KontaktResource[], kategorie: string) {
		if (!isNil(kontakte)) {
			const newValue = kontakte.some(kontakt => kontakt._links.self.href === this.selectedKontakt._links.self.href);
			if (newValue != this.assignedAs[kategorie]) {
				const formControl = this.getFormControl(kategorie);
				if (newValue != formControl.value) {
					formControl.setValue(newValue);
				}
				this.assignedAs[kategorie] = newValue;
			}
		}
	}

	private createUpdateSubscription(kontakte$: Observable<KontaktResource[]>, kategorie: string) {
		this.subscriptions.push(kontakte$.subscribe(kontakte => this.updateAssignedValue(kontakte, kategorie)));
	}

	private formatTitle(): string {
		if (this.selectedKontakt.juristischePerson) {
			let title = this.selectedKontakt.firmenName;
			if (this.selectedKontakt.firmenName1) {
				title += ', ' + this.selectedKontakt.firmenName1;
			}
			if (this.selectedKontakt.firmenName2) {
				title += ', ' + this.selectedKontakt.firmenName2;
			}
			return title;
		} else {
			let title = '';
			if (this.selectedKontakt.vorname) {
				title += this.selectedKontakt.vorname + ' ';
			}
			return title + this.selectedKontakt.name;
		}
	}
}
