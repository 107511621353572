import {Action} from '@ngrx/store';
import {getUrl} from '@ngxp/rest';
import {createEmptyStateResource, createStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {LoadVerfahrenSingleSuccessAction, VerfahrenActions} from '@schir-int-client/verfahren-shared';
import {VerfuegungActions, VerfuegungDeleteSuccessAction} from '@schir-int-client/verfuegung-shared';
import {VorgangActions} from '@schir-int-client/vorgang-shared';
import {
	KorrespondenzVorlageActions,
	LoadKorrespondenzVorlageAction,
	LoadKorrespondenzVorlageSuccessAction,
} from 'libs/korrespondenz-shared/src/lib/korrespondenz-vorlage/korrespondenz-vorlage.actions';
import {KorrespondenzVorlageResource} from 'libs/korrespondenz-shared/src/lib/korrespondenz-vorlage/korrespondenz-vorlage.model';
import {VorlageResource} from '@schir-int-client/vorlage-shared';
import {isNil} from 'lodash-es';
import {
	AdressatenEnrichedAction,
	AufgabeActions,
	ClearAdressatenByAufgabeAction,
	CreateAufgabeSuccessAction,
	LoadAufgabenByVerfuegungSuccessAction,
	LoadAufgabeSuccessAction,
	VorlageLoadedAction,
} from './aufgabe.actions';
import {AufgabeLinkRel} from './aufgabe.linkrel';
import {AdressatWithResource, AufgabeListResource, AufgabeResource} from './aufgabe.model';
import {AufgabeService} from './aufgabe.service';
import {AdressverwaltungActions, UpdateKontaktSuccessAction} from '@schir-int-client/adressverwaltung-shared';

export interface AufgabeState {
	selectedAufgabe: AufgabeResource;
	aufgaben: StateResource<AufgabeListResource>;
	adressatenByAufgabe: {
		[aufgabeUri: string]: AdressatWithResource[]
	};
	vorlagenResourcesByUri: {
		[vorlageUri: string]: VorlageResource
	};
	korrespondenzVorlageByUri: {
		[korrespondenzVorlageUri: string]: StateResource<KorrespondenzVorlageResource>
	};
}

export const initialState: AufgabeState = {
	selectedAufgabe: null,
	aufgaben: createEmptyStateResource(),
	adressatenByAufgabe: {},
	vorlagenResourcesByUri: {},
	korrespondenzVorlageByUri: {},
};

export function aufgabeReducer(state: AufgabeState = initialState, action: Action): AufgabeState {
	switch (action.type) {
		case AufgabeActions.CREATE_AUFGABE_SUCCESS:
			return {
				...state,
				selectedAufgabe: (<CreateAufgabeSuccessAction>action).aufgabe,
				aufgaben: { ...state.aufgaben, reload: true },
			};
		case AufgabeActions.UPDATE_AUFGABE_SUCCESS:
			return {
				...state,
				aufgaben: { ...state.aufgaben, reload: true },
			};
		case AufgabeActions.LOAD_AUFGABEN_BY_VERFUEGUNG:
			return {
				...state,
				aufgaben: { ...state.aufgaben, loading: true },
			};
		case AufgabeActions.LOAD_AUFGABEN_BY_VERFUEGUNG_SUCCESS:
			const loadedAction = <LoadAufgabenByVerfuegungSuccessAction>action;
			const verfuegungLoadedHref = loadedAction.verfuegung._links.self.href;

			if (state.selectedAufgabe && verfuegungLoadedHref === state.selectedAufgabe._links['verfuegung'].href) {
				return {
					...state,
					aufgaben: createStateResource(loadedAction.aufgaben),
				};
			}
			return {
				...state,
				aufgaben: createStateResource(loadedAction.aufgaben),
				selectedAufgabe: getFirstAufgabeAfterSorting(loadedAction.aufgaben._embedded),
			};
		case AufgabeActions.LOAD_AUFGABE_SUCCESS:
			return {
				...state,
				selectedAufgabe: (<LoadAufgabeSuccessAction>action).aufgabe,
			};
		case VerfuegungActions.SET_SELECTED_VERFUEGUNG_ACTION:
		case VerfuegungActions.LOAD_VERFUEGUNG_SUCCESS:
			return {
				...state,
				aufgaben: { ...state.aufgaben, reload: true },
			};

		case AufgabeActions.ADRESSATEN_ENRICHED:
			let adressatenByAufgabe = undefined;
			if (!isNil((<AdressatenEnrichedAction>action).adressaten)) {
				adressatenByAufgabe = (<AdressatenEnrichedAction>action).adressaten;
			}
			return {
				...state,
				adressatenByAufgabe:
					{
						...state.adressatenByAufgabe,
						[(<AdressatenEnrichedAction>action).aufgabeUri]: adressatenByAufgabe,
					},
			};
		case AufgabeActions.CLEAR_ADRESSATEN_BY_AUFGABE:
			return {
				...state,
				adressatenByAufgabe:
					{
						...state.adressatenByAufgabe,
						[(<ClearAdressatenByAufgabeAction>action).aufgabeUri]: undefined,
					},
			};
		case AufgabeActions.VORLAGE_LOADED:
			return {
				...state,
				vorlagenResourcesByUri:
					{
						...state.vorlagenResourcesByUri,
						[getUrl((<VorlageLoadedAction>action).vorlage)]: (<VorlageLoadedAction>action).vorlage,
					},
			};
		case VerfahrenActions.LOAD_VERFAHREN_SINGLE_SUCCESS:
			const verfahrenAction = (<LoadVerfahrenSingleSuccessAction>action);
			const loadedVerfahren = verfahrenAction.verfahren;
			const predecessor = verfahrenAction.predecessor;

			if (!predecessor || (loadedVerfahren && predecessor && predecessor.aktenzeichen === loadedVerfahren.aktenzeichen)) {
				return state;
			}
			return {
				...state,
				selectedAufgabe: null,
			};
		case VorgangActions.LOAD_VORGANG_SUCCESS:
			return state;
		case KorrespondenzVorlageActions.CREATE_KORRESPONDENZ_VORLAGE_SUCCESS:
		case KorrespondenzVorlageActions.DELETE_KORRESPONDENZ_VORLAGE_SUCCESS:
			return {
				...state,
				aufgaben: { ...state.aufgaben, reload: true },
			};
		case AufgabeActions.CLEAR_SELECTED_AUFGABE:
			return {
				...state,
				selectedAufgabe: null,
			};
		case KorrespondenzVorlageActions.LOAD_KORRESPONDENZ_VORLAGE:
			return {
				...state,
				korrespondenzVorlageByUri:
					{
						...state.korrespondenzVorlageByUri,
						[getUrl((<LoadKorrespondenzVorlageAction>action).aufgabeResource, AufgabeLinkRel.KORRESPONDENZ_VORLAGE)]: createEmptyStateResource(true),
					},
			};
		case KorrespondenzVorlageActions.LOAD_KORRESPONDENZ_VORLAGE_SUCCESS:
			return {
				...state,
				korrespondenzVorlageByUri:
					{
						...state.korrespondenzVorlageByUri,
						[getUrl((<LoadKorrespondenzVorlageSuccessAction>action).korrepsondenzVorlage)]: createStateResource((<LoadKorrespondenzVorlageSuccessAction>action).korrepsondenzVorlage),
					},
			};
		case VerfuegungActions.CREATE_VERFUEGUNG_BY_VORGANG_SUCCESS:
			return {
				...state,
				aufgaben: { ...state.aufgaben, reload: true },
			};
		case VerfuegungActions.CREATE_KORRESPONDENZ_VERFUEGUNG_BY_VORGANG_SUCCESS:
			return {
				...state,
				aufgaben: { ...state.aufgaben, reload: true },
			};
		case AdressverwaltungActions.UPDATE_KONTAKT_SUCCESS:
			const updatedKontakt = (<UpdateKontaktSuccessAction>action).kontakt;
			const updatedAdressatenByAufgabe = {...state.adressatenByAufgabe};

			Object.keys(updatedAdressatenByAufgabe).forEach(aufgabeUri => {
				let adressaten: AdressatWithResource[] = [...(updatedAdressatenByAufgabe[aufgabeUri] || [])];
				if(adressaten){
					adressaten.forEach((adressat, index) => {
						if (adressat.kontakt === updatedKontakt._links.self.href) {
							adressaten[index] = { ...adressaten[index], resource: updatedKontakt };
						}
					});
				}
				updatedAdressatenByAufgabe[aufgabeUri] = adressaten;
			})
			return {
				...state,
				adressatenByAufgabe : updatedAdressatenByAufgabe
			}
		case VerfuegungActions.DELETE_VERFUEGUNG_SUCCESS:
			const verfuegung = (<VerfuegungDeleteSuccessAction>action).deleted;
			const selected = state.selectedAufgabe;

			if (selected?._links['verfuegung'].href == verfuegung._links.self.href) {
				return {
					...initialState,
					aufgaben: { ...createEmptyStateResource() },
					selectedAufgabe: null,
				};
			}

			return state;
		default:
			return state;
	}
}

//required because component sorts aufgaben before rendering
function getFirstAufgabeAfterSorting(embeddedResource) {
	if (embeddedResource) {
		const aufgabenList = embeddedResource['aufgabeList'];
		const aufgaben: AufgabeResource[] = <AufgabeResource[]>aufgabenList;
		const sorted: AufgabeResource[] = AufgabeService.sortAufgabenByTyp(aufgaben);
		return sorted[0];
	}
	return null;
}
