import {Component, Input} from '@angular/core';
import {
	AnEigentuemerZuSenden,
	AufgabeMitteilungBody,
	AufgabeResource,
} from '@schir-int-client/aufgabe-shared';
import {isNil} from 'lodash-es';
import {EintragungsmitteilungMitteilungen} from '../../../aufgaben/aufgabe-mitteilung-editor/aufgabe-mitteilung-editor.component';

@Component({
	selector: 'schir-int-client-aufgabe-mitteilung-body',
	templateUrl: './aufgabe-mitteilung-body.component.html',
	styleUrls: ['./aufgabe-mitteilung-body.component.scss'],
})
export class AufgabeMitteilungBodyComponent {
	@Input() aufgabe: AufgabeResource;

	readonly mitteilungEmpfaenger = EintragungsmitteilungMitteilungen;

	constructor() { }

	get body(): AufgabeMitteilungBody {
		return <AufgabeMitteilungBody>this.aufgabe.body;
	}

	get eigentuemerNeu(): string {
		return this.generateEigentuemerMitteilung(this.body.eigentuemerNeuMitteilung);
	}

	get eigentuemerAlt(): string {
		return this.generateEigentuemerMitteilung(this.body.eigentuemerAltMitteilung);
	}

	get eingetragenerEigentuemer(): string {
		return this.generateEigentuemerMitteilung(this.body.eingetragenerEigentuemerMitteilung);
	}

	get glaeubigerNeu(): string {
		return this.formatMitteilung([
			this.body.glaeubigerNeuMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : undefined,
			this.body.glaeubigerNeuMitteilung.zertifikatsBerichtigung ? EintragungsmitteilungMitteilungen.SCHIFFSZERTIFIKAT : undefined]);
	}

	get glaeubigerAlt(): string {
		return this.formatMitteilung([
			this.body.glaeubigerAltMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : undefined,
			this.body.glaeubigerAltMitteilung.zertifikatsBerichtigung ? EintragungsmitteilungMitteilungen.SCHIFFSZERTIFIKAT : undefined]);
	}

	get notar(): string {
		return this.body.notarMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : '';
	}

	get bsh(): string {
		return this.body.bshMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BEGLAUBIGTE_BLATTABSCHRIFT : '';
	}

	get rechtsanwaelte(): string {
		return this.formatMitteilung([
			this.body.rechtsanwaelteMitteilung.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : undefined,
			this.body.rechtsanwaelteMitteilung.zertifikatsBerichtigung ? EintragungsmitteilungMitteilungen.SCHIFFSZERTIFIKAT : undefined]);
	}

	private generateEigentuemerMitteilung(content: AnEigentuemerZuSenden) {
		return this.formatMitteilung([
			content.blattabschrift ? EintragungsmitteilungMitteilungen.BLATTABSCHRIFT : undefined,
			content.zertifikatsBerichtigung ? EintragungsmitteilungMitteilungen.SCHIFFSZERTIFIKAT : undefined,
			content.auszugsEinreichung ? EintragungsmitteilungMitteilungen.ZERTIFIKATSAUSZUG : undefined,
		]);
	}

	private formatMitteilung(mitteilungen: (string | undefined)[]): string {
		return mitteilungen.filter(text => !isNil(text)).join(', ');
	}
}
