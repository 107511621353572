import {Injectable, OnDestroy} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
	Aufgabe,
	AufgabeFacade,
	AufgabenTyp,
	AufgabeRechnungsstellungErledigung,
	AufgabeVersandTaetigkeit,
	AufgabeZertifikatTaetigkeit,
} from '@schir-int-client/aufgabe-shared';
import {isNil} from 'lodash-es';
import {Subscription} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CreateAufgabeFormService implements OnDestroy {
	private aufgabeSubscription: Subscription;

	static readonly feldAufgabeTyp = 'typ';
	static readonly feldAufgabeNotiz = 'notiz';
	static readonly feldAufgabeBody = 'body';

	static readonly feldZertifikatTaetigkeit = 'taetigkeit';
	static readonly feldZertifikatVersandTaetigkeit = 'versandTaetigkeit';
	static readonly feldZertifikatSendenAn = 'sendenAn';

	static readonly feldVersandTaetigkeit = 'taetigkeit';
	static readonly feldVersandEmpfaenger = 'empfaenger';
	static readonly feldVersandFaxEmpfaenger = 'faxEmpfaenger';

	static readonly feldRegisterabschriftBeglaubigte = 'beglaubigte';
	static readonly feldRegisterabschriftEinfache = 'einfache';

	static readonly groupEintragungsmitteilungEigentuemerNeu = 'eigentuemerNeuMitteilung';
	static readonly feldEintragungsmitteilungEigentuemerNeuMitteilung = 'anEigentuemerNeuSenden';
	static readonly feldEintragungsmitteilungEigentuemerNeuBlattabschrift = 'blattabschrift';
	static readonly feldEintragungsmitteilungEigentuemerNeuZertifikatsberichtigung = 'zertifikatsBerichtigung';
	static readonly feldEintragungsmitteilungEigentuemerNeuAuszugseinreichung = 'auszugsEinreichung';

	static readonly groupEintragungsmitteilungEigentuemerAlt = 'eigentuemerAltMitteilung';
	static readonly feldEintragungsmitteilungEigentuemerAltMitteilung = 'anEigentuemerAltSenden';
	static readonly feldEintragungsmitteilungEigentuemerAltBlattabschrift = 'blattabschrift';
	static readonly feldEintragungsmitteilungEigentuemerAltZertifikatsberichtigung = 'zertifikatsBerichtigung';
	static readonly feldEintragungsmitteilungEigentuemerAltAuszugseinreichung = 'auszugsEinreichung';

	static readonly groupEintragungsmitteilungEingetragenerEigentuemer = 'eingetragenerEigentuemerMitteilung';
	static readonly feldEintragungsmitteilungEingetragenerEigentuemerMitteilung = 'anEingetragenerEigentuemerSenden';
	static readonly feldEintragungsmitteilungEingetragenerEigentuemerBlattabschrift = 'blattabschrift';
	static readonly feldEintragungsmitteilungEingetragenerEigentuemerZertifikatsberichtigung = 'zertifikatsBerichtigung';
	static readonly feldEintragungsmitteilungEingetragenerEigentuemerAuszugseinreichung = 'auszugsEinreichung';

	static readonly groupEintragungsmitteilungGlaeubigerNeu = 'glaeubigerNeuMitteilung';
	static readonly feldEintragungsmitteilungGlaeubigerNeuMitteilung = 'anGlaeubigerNeuSenden';
	static readonly feldEintragungsmitteilungGlaeubigerNeuBlattabschrift = 'blattabschrift';
	static readonly feldEintragungsmitteilungGlaeubigerNeuZertifikatsberichtigung = 'zertifikatsBerichtigung';

	static readonly groupEintragungsmitteilungGlaeubigerAlt = 'glaeubigerAltMitteilung';
	static readonly feldEintragungsmitteilungGlaeubigerAltMitteilung = 'anGlaeubigerAltSenden';
	static readonly feldEintragungsmitteilungGlaeubigerAltBlattabschrift = 'blattabschrift';
	static readonly feldEintragungsmitteilungGlaeubigerAltZertifikatsberichtigung = 'zertifikatsBerichtigung';

	static readonly groupEintragungsmitteilungNotar = 'notarMitteilung';
	static readonly feldEintragungsmitteilungNotarMitteilung = 'anNotarSenden';
	static readonly feldEintragungsmitteilungNotarBlattabschrift = 'blattabschrift';

	static readonly feldEintragungsmitteilungBehoerdenMitteilungen = 'anBehoerdenSenden';

	static readonly groupEintragungsmitteilungBsh = 'bshMitteilung';
	static readonly feldEintragungsmitteilungBshMitteilung = 'anBshSenden';
	static readonly feldEintragungsmitteilungBshBlattabschrift = 'blattabschrift';

	static readonly groupEintragungsmitteilungRechtsanwaelte = 'rechtsanwaelteMitteilung';
	static readonly feldEintragungsmitteilungRechtsanwaelteMitteilung = 'anRechtsanwaelteSenden';
	static readonly feldEintragungsmitteilungRechtsanwaelteBlattabschrift = 'blattabschrift';
	static readonly feldEintragungsmitteilungRechtsanwaelteZertifikatsberichtigung = 'zertifikatsBerichtigung';

	static readonly feldMessbriefplusUsig = 'mitUsigVersehen';
	static readonly feldMessbriefplusVermerk = 'mitVermerkVersehen';
	static readonly feldMessbriefOriginal = 'original';
	static readonly groupMessbriefOriginal = 'originalAn';
	static readonly feldMessbriefOriginalAnEigentuemer = 'eigentuemer';
	static readonly feldMessbriefOriginalAnVertreter = 'vertreter';
	static readonly feldMessbriefVorabKopie = 'vorabKopie';
	static readonly feldMessbriefZdA = 'zda';
	static readonly feldMessbriefErgaenzen = 'ergaenzen';
	static readonly groupMessbriefErgaenzen = 'ergaenzenSenden';
	static readonly feldMessbriefErgaenzenAnBSH = 'bsh';
	static readonly feldMessbriefErgaenzenAnEinreicher = 'einreicher';


	static readonly feldWiedervorlageWiedervorlageDatum = 'wiedervorlageDatum';

	static readonly feldRechnungsstellungErledigung = 'erledigung';

	static readonly feldVorlageId = 'vorlageId';
	static readonly feldDatum = 'datum';
	static readonly feldRechtspfleger = 'rechtspfleger';

	static readonly feldLfprSchuldtitelEintragung = 'eintragung';
	static readonly feldLfprSchuldtitelAbschrift = 'abschrift';
	static readonly feldLfprSchuldtitelVollstreckungsunterlagen = 'vollstreckungsunterlagen';

	static readonly feldLfprMitteilungEigentuemerNeu = 'anEigentuemerNeu';
	static readonly groupLfprMitteilungEigentuemerNeu = 'eigentuemerNeu';
	static readonly feldLfprMitteilungEigentuemerAlt = 'anEigentuemerAlt';
	static readonly groupLfprMitteilungEigentuemerAlt = 'eigentuemerAlt';
	static readonly feldLfprMitteilungUeberNotar = 'ueberNotar';
	static readonly feldLfprMitteilungUeberAnwalt = 'ueberAnwalt';
	static readonly feldLfprMitteilungNotar = 'anNotar';
	static readonly groupLfprMitteilungNotar = 'notar';
	static readonly feldLfprMitteilungNotarUvzNr = 'uvzNr';
	static readonly feldLfprMitteilungLFBA = 'anLuftfahrtBundesamt';
	static readonly groupLfprMitteilungLFBA = 'luftfahrtBundesAmt';
	static readonly feldLfprMitteilungLFBANrRolle = 'nrLuftfahrzeugrolle';
	static readonly feldLfprMitteilungGlaeubigerNeu = 'anGlaeubigerNeu';
	static readonly groupLfprMitteilungGlaeubigerNeu = 'glaeubigerNeu';
	static readonly feldLfprMitteilungGlaeubigerAlt = 'anGlaeubigerAlt';
	static readonly groupLfprMitteilungGlaeubigerAlt = 'glaeubigerAlt';
	static readonly feldLfprMitteilungGlaeubigerRuecksendung = 'ruecksendung';
	static readonly feldLfprMitteilungBerechtigter = 'anBerechtigter';
	static readonly groupLfprMitteilungBerechtigter = 'berechtigter';
	static readonly feldLfprMitteilungBerechtigterNr = 'berechtigterNr';
	static readonly feldLfprMitteilungRechtsanwalt = 'anRechtsanwalt';
	static readonly groupLfprMitteilungRechtsanwalt = 'rechtsanwalt';
	static readonly feldLfprMitteilungAnwaltZuAz = 'zuAz';

	static readonly feldLfprAbschriftEinfach = 'einfacheAn';
	static readonly groupLfprAbschriftEinfach = 'einfache';
	static readonly feldLfprAbschriftBeglaubigt = 'beglaubigteAn';
	static readonly groupLfprAbschriftBeglaubigt = 'beglaubigte';
	static readonly feldLfprAbschriftAnGlaeubigerNeu = 'glaeubigerNeu';
	static readonly feldLfprAbschriftGlaeubigerNeuNr = 'glaeubigerNeuNr';
	static readonly feldLfprAbschriftAnGlaeubigerAlt = 'glaeubigerAlt';
	static readonly feldLfprAbschriftGlaeubigerAltNr = 'glaeubigerAltNr';
	static readonly feldLfprAbschriftAnNotar = 'notar';
	static readonly feldLfprAbschriftAnRechtsanwalt = 'rechtsanwalt';
	static readonly feldLfprAbschriftAnEigentuemerNeu = 'eigentuemerNeu';
	static readonly feldLfprAbschriftAnEigentuemerAlt = 'eigentuemerAlt';

	static readonly feldLfprKostenTaetigkeit = 'taetigkeit';
	static readonly feldLfprKostenErfordernVon = 'traeger';
	static readonly feldLfprKostenBerechtigteNr = 'berechtigteNr';
	static readonly feldLfprKostenfreiGrund = 'grund';

	static readonly feldLfprAufbewahrenBis = 'aufbewahrenBis';


	static readonly integerValidator = Validators.pattern('^\\d+$');

	readonly typeSubscription: Subscription;
	private mitteilungSubscription: Subscription;
	readonly form: UntypedFormGroup;

	constructor(private formBuilder: UntypedFormBuilder, private facade: AufgabeFacade) {
		this.form = this.createForm();
		this.disableAllTypeGroups();
		this.typeSubscription = this.buildTypeSubscription();
	}

	private createForm(): UntypedFormGroup {
		return this.formBuilder.group({
			[CreateAufgabeFormService.feldAufgabeTyp]: new UntypedFormControl(null),
			[AufgabenTyp.FINANZSANKTIONSLISTE]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.ZERTIFIKAT]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldZertifikatTaetigkeit]: new UntypedFormControl(AufgabeZertifikatTaetigkeit.FERTIGEN, [Validators.required]),
					[CreateAufgabeFormService.feldZertifikatVersandTaetigkeit]: new UntypedFormControl(AufgabeVersandTaetigkeit.SENDEN_AN, [Validators.required]),
					[CreateAufgabeFormService.feldZertifikatSendenAn]: new UntypedFormControl(null, [Validators.required]),
				}),
			}),
			[AufgabenTyp.ALTES_ZERTIFIKAT]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.ALTER_AUSZUG]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.ZERTIFIKATSAUSZUG]: this.createVersandWithFaxFormGroup(),
			[AufgabenTyp.ABSCHRIFT_ZERTIFIKATSAUSZUG]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldVersandTaetigkeit]: new UntypedFormControl(AufgabeVersandTaetigkeit.SENDEN_AN, [Validators.required]),
					[CreateAufgabeFormService.feldVersandEmpfaenger]: new UntypedFormControl(null, [Validators.required]),
				}),
			}),
			[AufgabenTyp.HYPOTHEKENBESCHEINIGUNG]: this.createVersandWithFaxFormGroup(),
			[AufgabenTyp.EIGENTUEMERBESCHEINIGUNG]: this.createVersandWithFaxFormGroup(),
			[AufgabenTyp.LOESCHUNGSBESCHEINIGUNG]: this.createVersandWithFaxFormGroup(),
			[AufgabenTyp.LASTENFREIHEITSBESCHEINIGUNG]: this.createVersandWithFaxFormGroup(),
			[AufgabenTyp.NAMENSAENDERUNGSBESCHEINIGUNG]: this.createVersandWithFaxFormGroup(),
			[AufgabenTyp.SONSTIGE]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.ABLAGE]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.REGISTERABSCHRIFT]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldRegisterabschriftBeglaubigte]: new UntypedFormControl(null, [CreateAufgabeFormService.integerValidator]),
					[CreateAufgabeFormService.feldRegisterabschriftEinfache]: new UntypedFormControl(null, [CreateAufgabeFormService.integerValidator]),
				}),
			}),
			[AufgabenTyp.EINTRAGUNGSMITTEILUNG]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldEintragungsmitteilungEigentuemerNeuMitteilung]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupEintragungsmitteilungEigentuemerNeu]: this.formBuilder.group({
						[CreateAufgabeFormService.feldEintragungsmitteilungEigentuemerNeuBlattabschrift]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldEintragungsmitteilungEigentuemerNeuZertifikatsberichtigung]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldEintragungsmitteilungEigentuemerNeuAuszugseinreichung]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldEintragungsmitteilungEigentuemerAltMitteilung]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupEintragungsmitteilungEigentuemerAlt]: this.formBuilder.group({
						[CreateAufgabeFormService.feldEintragungsmitteilungEigentuemerAltBlattabschrift]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldEintragungsmitteilungEigentuemerAltZertifikatsberichtigung]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldEintragungsmitteilungEigentuemerAltAuszugseinreichung]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldEintragungsmitteilungEingetragenerEigentuemerMitteilung]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupEintragungsmitteilungEingetragenerEigentuemer]: this.formBuilder.group({
						[CreateAufgabeFormService.feldEintragungsmitteilungEingetragenerEigentuemerBlattabschrift]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldEintragungsmitteilungEingetragenerEigentuemerZertifikatsberichtigung]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldEintragungsmitteilungEingetragenerEigentuemerAuszugseinreichung]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldEintragungsmitteilungGlaeubigerNeuMitteilung]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupEintragungsmitteilungGlaeubigerNeu]: this.formBuilder.group({
						[CreateAufgabeFormService.feldEintragungsmitteilungGlaeubigerNeuBlattabschrift]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldEintragungsmitteilungGlaeubigerNeuZertifikatsberichtigung]: new UntypedFormControl(null),
					}),

					[CreateAufgabeFormService.feldEintragungsmitteilungGlaeubigerAltMitteilung]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupEintragungsmitteilungGlaeubigerAlt]: this.formBuilder.group({
						[CreateAufgabeFormService.feldEintragungsmitteilungGlaeubigerAltBlattabschrift]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldEintragungsmitteilungGlaeubigerAltZertifikatsberichtigung]: new UntypedFormControl(null),
					}),

					[CreateAufgabeFormService.feldEintragungsmitteilungNotarMitteilung]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupEintragungsmitteilungNotar]: this.formBuilder.group({
						[CreateAufgabeFormService.feldEintragungsmitteilungNotarBlattabschrift]: new UntypedFormControl(null),
					}),

					[CreateAufgabeFormService.feldEintragungsmitteilungBehoerdenMitteilungen]: new UntypedFormControl(null),

					[CreateAufgabeFormService.feldEintragungsmitteilungBshMitteilung]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupEintragungsmitteilungBsh]: this.formBuilder.group({
						[CreateAufgabeFormService.feldEintragungsmitteilungBshBlattabschrift]: new UntypedFormControl(null),
					}),

					[CreateAufgabeFormService.feldEintragungsmitteilungRechtsanwaelteMitteilung]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupEintragungsmitteilungRechtsanwaelte]: this.formBuilder.group({
						[CreateAufgabeFormService.feldEintragungsmitteilungRechtsanwaelteBlattabschrift]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldEintragungsmitteilungRechtsanwaelteZertifikatsberichtigung]: new UntypedFormControl(null),
					}),
				}),
			}),
			[AufgabenTyp.MESSBRIEF]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldMessbriefplusUsig]: new UntypedFormControl(null),
					[CreateAufgabeFormService.feldMessbriefplusVermerk]: new UntypedFormControl(null),
					[CreateAufgabeFormService.feldMessbriefOriginal]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupMessbriefOriginal]: this.formBuilder.group({
						[CreateAufgabeFormService.feldMessbriefOriginalAnEigentuemer]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldMessbriefOriginalAnVertreter]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldMessbriefVorabKopie]: new UntypedFormControl(null),
					[CreateAufgabeFormService.feldMessbriefZdA]: new UntypedFormControl(null),
					[CreateAufgabeFormService.feldMessbriefErgaenzen]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupMessbriefErgaenzen]: this.formBuilder.group({
						[CreateAufgabeFormService.feldMessbriefErgaenzenAnBSH]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldMessbriefErgaenzenAnEinreicher]: new UntypedFormControl(null),
					}),
				}),
			}),
			[AufgabenTyp.WIEDERVORLAGE]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldWiedervorlageWiedervorlageDatum]: new UntypedFormControl(this.createDefaultDate()),
				}),
			}),
			[AufgabenTyp.RECHNUNGSSTELLUNG]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldRechnungsstellungErledigung]: new UntypedFormControl(AufgabeRechnungsstellungErledigung.ERLEDIGT, [Validators.required]),
				}),
			}),
			[AufgabenTyp.KOSTEN]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.ANSCHREIBEN]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldVorlageId]: new UntypedFormControl(null),
					[CreateAufgabeFormService.feldDatum]: new UntypedFormControl(new Date()),
					[CreateAufgabeFormService.feldRechtspfleger]: null,
					[CreateAufgabeFormService.feldVersandTaetigkeit]: new UntypedFormControl(AufgabeVersandTaetigkeit.SENDEN_AN),
					[CreateAufgabeFormService.feldVersandEmpfaenger]: new UntypedFormControl(null),
				}),
			}),
			[AufgabenTyp.LFPR_REGISTERAKTE_ANLEGEN]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.LFPR_SCHULDTITEL]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldLfprSchuldtitelEintragung]: new UntypedFormControl(null),
					[CreateAufgabeFormService.feldLfprSchuldtitelAbschrift]: new UntypedFormControl(null),
					[CreateAufgabeFormService.feldLfprSchuldtitelVollstreckungsunterlagen]: new UntypedFormControl(null),
				}),
			}),
			[AufgabenTyp.LFPR_MITTEILUNG]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldLfprMitteilungEigentuemerNeu]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupLfprMitteilungEigentuemerNeu]: this.formBuilder.group({
						[CreateAufgabeFormService.feldLfprMitteilungUeberAnwalt]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprMitteilungUeberNotar]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldLfprMitteilungEigentuemerAlt]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupLfprMitteilungEigentuemerAlt]: this.formBuilder.group({
						[CreateAufgabeFormService.feldLfprMitteilungUeberAnwalt]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprMitteilungUeberNotar]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldLfprMitteilungNotar]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupLfprMitteilungNotar]: this.formBuilder.group({
						[CreateAufgabeFormService.feldLfprMitteilungNotarUvzNr]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldLfprMitteilungLFBA]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupLfprMitteilungLFBA]: this.formBuilder.group({
						[CreateAufgabeFormService.feldLfprMitteilungLFBANrRolle]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldLfprMitteilungGlaeubigerNeu]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupLfprMitteilungGlaeubigerNeu]: this.formBuilder.group({
						[CreateAufgabeFormService.feldLfprMitteilungGlaeubigerRuecksendung]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldLfprMitteilungGlaeubigerAlt]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupLfprMitteilungGlaeubigerAlt]: this.formBuilder.group({
						[CreateAufgabeFormService.feldLfprMitteilungGlaeubigerRuecksendung]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldLfprMitteilungBerechtigter]: new UntypedFormControl(null),
					[CreateAufgabeFormService.feldLfprMitteilungBerechtigterNr]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupLfprMitteilungBerechtigter]: this.formBuilder.group({
						[CreateAufgabeFormService.feldLfprMitteilungUeberAnwalt]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprMitteilungUeberNotar]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldLfprMitteilungRechtsanwalt]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupLfprMitteilungRechtsanwalt]: this.formBuilder.group({
						[CreateAufgabeFormService.feldLfprMitteilungAnwaltZuAz]: new UntypedFormControl(null),
					}),
				}),
			}),
			[AufgabenTyp.LFPR_STATISTIK]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.LFPR_VERWEISUNGSVERMERK]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.LFPR_DB_AKTUALISIEREN]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.LFPR_BLATTABSCHRIFT]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldLfprAbschriftEinfach]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupLfprAbschriftEinfach]: this.formBuilder.group({
						[CreateAufgabeFormService.feldLfprAbschriftAnGlaeubigerNeu]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftGlaeubigerNeuNr]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftAnGlaeubigerAlt]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftGlaeubigerAltNr]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftAnNotar]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftAnEigentuemerNeu]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftAnEigentuemerAlt]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftAnRechtsanwalt]: new UntypedFormControl(null),
					}),
					[CreateAufgabeFormService.feldLfprAbschriftBeglaubigt]: new UntypedFormControl(null),
					[CreateAufgabeFormService.groupLfprAbschriftBeglaubigt]: this.formBuilder.group({
						[CreateAufgabeFormService.feldLfprAbschriftAnGlaeubigerNeu]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftGlaeubigerNeuNr]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftAnGlaeubigerAlt]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftGlaeubigerAltNr]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftAnNotar]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftAnEigentuemerNeu]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftAnEigentuemerAlt]: new UntypedFormControl(null),
						[CreateAufgabeFormService.feldLfprAbschriftAnRechtsanwalt]: new UntypedFormControl(null),
					}),
				}),
			}),
			[AufgabenTyp.LFPR_NEGATIVBESCHEINIGUNG]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.LFPR_KOSTEN]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldLfprKostenTaetigkeit]: new UntypedFormControl(null, [Validators.required]),
					[CreateAufgabeFormService.feldLfprKostenErfordernVon]: new UntypedFormControl(null),
					[CreateAufgabeFormService.feldLfprKostenBerechtigteNr]: new UntypedFormControl(null),
					[CreateAufgabeFormService.feldLfprKostenfreiGrund]: new UntypedFormControl(null),
				}),
			}),
			[AufgabenTyp.LFPR_WIEDERVORLAGE]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldWiedervorlageWiedervorlageDatum]: new UntypedFormControl(this.createDefaultDate()),
				}),
			}),
			[AufgabenTyp.LFPR_ERLEDIGT]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			}),
			[AufgabenTyp.LFPR_WEGLEGEN]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldLfprAufbewahrenBis]: new UntypedFormControl(this.createDefaultAufbewahrenDate(30)),
				}),
			}),
			[AufgabenTyp.LFPR_SAMMLUNG]: this.formBuilder.group({
				[CreateAufgabeFormService.feldAufgabeNotiz]: null,
				[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
					[CreateAufgabeFormService.feldLfprAufbewahrenBis]: new UntypedFormControl(this.createDefaultAufbewahrenDate(5)),
				}),
			}),
		});
	}

	private createVersandWithFaxFormGroup(): UntypedFormGroup {
		return this.formBuilder.group({
			[CreateAufgabeFormService.feldAufgabeNotiz]: null,
			[CreateAufgabeFormService.feldAufgabeBody]: this.formBuilder.group({
				[CreateAufgabeFormService.feldVersandTaetigkeit]: new UntypedFormControl(AufgabeVersandTaetigkeit.SENDEN_AN, [Validators.required]),
				[CreateAufgabeFormService.feldVersandEmpfaenger]: new UntypedFormControl(null, [Validators.required]),
				[CreateAufgabeFormService.feldVersandFaxEmpfaenger]: new UntypedFormControl(null, [Validators.required]),
			}),
		});
	}

	private createMitteilungSubscription(): Subscription {
		this.changeStatusBySelection();

		return this.controlGroupEintragungsmitteilung.controls[CreateAufgabeFormService.feldAufgabeBody].valueChanges.subscribe(() => {
			this.changeStatusBySelection();
		});
	}

	private changeStatusBySelection(): void {
		const bodyGroup = <UntypedFormGroup>this.controlGroupEintragungsmitteilung.controls[CreateAufgabeFormService.feldAufgabeBody];
		this.changeStatus(bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungEigentuemerNeu], bodyGroup.controls[CreateAufgabeFormService.feldEintragungsmitteilungEigentuemerNeuMitteilung].value);
		this.changeStatus(bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungEigentuemerAlt], bodyGroup.controls[CreateAufgabeFormService.feldEintragungsmitteilungEigentuemerAltMitteilung].value);
		this.changeStatus(bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungEingetragenerEigentuemer], bodyGroup.controls[CreateAufgabeFormService.feldEintragungsmitteilungEingetragenerEigentuemerMitteilung].value);
		this.changeStatus(bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungGlaeubigerNeu], bodyGroup.controls[CreateAufgabeFormService.feldEintragungsmitteilungGlaeubigerNeuMitteilung].value);
		this.changeStatus(bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungGlaeubigerAlt], bodyGroup.controls[CreateAufgabeFormService.feldEintragungsmitteilungGlaeubigerAltMitteilung].value);
		this.changeStatus(bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungNotar], bodyGroup.controls[CreateAufgabeFormService.feldEintragungsmitteilungNotarMitteilung].value);
		this.changeStatus(bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungBsh], bodyGroup.controls[CreateAufgabeFormService.feldEintragungsmitteilungBshMitteilung].value);
		this.changeStatus(bodyGroup.controls[CreateAufgabeFormService.groupEintragungsmitteilungRechtsanwaelte], bodyGroup.controls[CreateAufgabeFormService.feldEintragungsmitteilungRechtsanwaelteMitteilung].value);
	}

	private createDefaultDate(): Date {
		const date = new Date();
		date.setDate(new Date().getDate() + (8 * 7));
		return date;
	}

	private createDefaultAufbewahrenDate(fullYears: number): Date {
		const date = new Date();
		date.setFullYear(date.getFullYear() + fullYears, 11, 31);
		return date;
	}

	private buildTypeSubscription(): Subscription {
		return this.form.controls[CreateAufgabeFormService.feldAufgabeTyp].valueChanges.subscribe(selectedType => {
			if (selectedType != null) {
				this.disableAllTypeGroups();
				this.enableAufgabenTyp(selectedType);
			}
		});
	}

	private disableAllTypeGroups(): void {
		Object.keys(AufgabenTyp).map(aufgabeTyp => AufgabenTyp[aufgabeTyp])
			.forEach(aufgabeTyp => this.disableAufgabenTyp(aufgabeTyp));
	}

	submit(): boolean {
		if (this.form.valid) {
			if (this.aufgabeSubscription) {
				this.facade.updateAufgabe(this.buildAufgabeByTyp(this.form.value.typ));
			} else {
				Object.values(AufgabenTyp).forEach(aufgabenTyp => {
					if (!isNil(this.form.value[aufgabenTyp]) || this.form.value.typ == aufgabenTyp) {
						this.facade.createAufgabe(this.buildAufgabeByTyp(aufgabenTyp));
					}
				});
			}

			return true;
		}
	}

	isValid(): boolean {
		return this.form.valid;
	}

	public buildAufgabeByTyp(typ: AufgabenTyp): Aufgabe {
		const formData = this.form.value;
		if (!isNil(formData[typ])) {
			let notiz = formData[typ].notiz;
			if (isNil(notiz) && typ == AufgabenTyp.FINANZSANKTIONSLISTE) {
				notiz = 'kein Eintrag vorhanden';
			}
			let result: Aufgabe = { ...formData[typ], notiz: notiz, typ };
			result = {
				...result,
				body: isNil(formData[typ][CreateAufgabeFormService.feldAufgabeBody]) ? null : formData[typ][CreateAufgabeFormService.feldAufgabeBody],
			};
			delete result[typ];
			return result;
		} else {
			return <Aufgabe>{ typ, body: null };
		}
	}

	subscribeToAufgabe() {
		this.aufgabeSubscription = this.facade.aufgabe.subscribe(aufgabe => {
			if (aufgabe) {
				this.form.patchValue(this.prepareForPatch(aufgabe));
			}
		});
	}

	prepareForPatch(aufgabe: Aufgabe): object {
		const body = this.removeNullValues(aufgabe.body);
		return { typ: aufgabe.typ, [aufgabe.typ]: { body: body, notiz: aufgabe.notiz } };
	}

	private removeNullValues(body: object): object {
		const result = { ...body };
		Object.getOwnPropertyNames(result).filter(prop => isNil(result[prop])).forEach(prop => delete result[prop]);
		return result;
	}

	get controlAufgabeTyp(): UntypedFormControl {
		return <UntypedFormControl>this.form.controls[CreateAufgabeFormService.feldAufgabeTyp];
	}

	get controlGroupFinanzsanktionsliste(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.FINANZSANKTIONSLISTE];
	}

	get controlGroupZertifikat(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.ZERTIFIKAT];
	}

	get controlGroupAltesZertifikat(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.ALTES_ZERTIFIKAT];
	}

	get controlGroupAlterAuszug(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.ALTER_AUSZUG];
	}

	get controlGroupZertifikatsauszug(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.ZERTIFIKATSAUSZUG];
	}

	get controlGroupAbschriftZertifikatsauszug(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.ABSCHRIFT_ZERTIFIKATSAUSZUG];
	}

	get controlGroupHypothekenbescheinigung(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.HYPOTHEKENBESCHEINIGUNG];
	}

	get controlGroupEigentuemerbescheinigung(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.EIGENTUEMERBESCHEINIGUNG];
	}

	get controlGroupLoeschungsbescheinigung(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LOESCHUNGSBESCHEINIGUNG];
	}

	get controlGroupLastenfreiheitsbescheinigung(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LASTENFREIHEITSBESCHEINIGUNG];
	}

	get controlGroupNamensaenderungsbescheinigung(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.NAMENSAENDERUNGSBESCHEINIGUNG];
	}

	get controlGroupSonstige(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.SONSTIGE];
	}

	get controlGroupAblage(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.ABLAGE];
	}

	get controlGroupRegisterabschrift(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.REGISTERABSCHRIFT];
	}

	get controlGroupEintragungsmitteilung(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.EINTRAGUNGSMITTEILUNG];
	}

	get controlGroupMessbrief(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.MESSBRIEF];
	}

	get controlGroupWiedervorlage(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.WIEDERVORLAGE];
	}

	get controlGroupRechnungsstellung(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.RECHNUNGSSTELLUNG];
	}

	get controlGroupKosten(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.KOSTEN];
	}

	get controlGroupAnschreiben(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.ANSCHREIBEN];
	}

	get controlGroupLfprRegisterakteAnlegen(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_REGISTERAKTE_ANLEGEN];
	}

	get controlGroupLfprSchuldtitel(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_SCHULDTITEL];
	}

	get controlGroupLfprMitteilung(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_MITTEILUNG];
	}

	get controlGroupLfprStatistik(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_STATISTIK];
	}

	get controlGroupLfprVerweisungsvermerk(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_VERWEISUNGSVERMERK];
	}

	get controlGroupLfprDBAktualisieren(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_DB_AKTUALISIEREN];
	}

	get controlGroupLfprBlattabschrift(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_BLATTABSCHRIFT];
	}

	get controlGroupLfprNegativbescheinigung(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_NEGATIVBESCHEINIGUNG];
	}

	get controlGroupLfprKosten(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_KOSTEN];
	}

	get controlGroupLfprWiedervorlage(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_WIEDERVORLAGE];
	}

	get controlGroupLfprErledigt(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_ERLEDIGT];
	}

	get controlGroupLfprWeglegen(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_WEGLEGEN];
	}

	get controlGroupLfprSammlung(): UntypedFormGroup {
		return <UntypedFormGroup>this.form.controls[AufgabenTyp.LFPR_SAMMLUNG];
	}


	public enableAufgabenTyp(aufgabenTyp: AufgabenTyp) {
		this.form.controls[aufgabenTyp].enable();
		if (this.hasFax(aufgabenTyp)) {
			this.disableFaxEmpfaenger(aufgabenTyp);
		}
		if (aufgabenTyp === AufgabenTyp.EINTRAGUNGSMITTEILUNG) {
			this.mitteilungSubscription = this.createMitteilungSubscription();
		}
	}

	public disableAufgabenTyp(aufgabenTyp: AufgabenTyp) {
		if (aufgabenTyp === AufgabenTyp.EINTRAGUNGSMITTEILUNG) {
			this.unsubscribeMitteilung();
		}
		this.form.controls[aufgabenTyp].disable();
	}

	private changeStatus(control: AbstractControl, enable: boolean) {
		if (enable && control.disabled) {
			control.enable();
		}
		if (!enable && !control.disabled) {
			control.disable();
		}
	}

	public hasFax(aufgabenTyp: AufgabenTyp): boolean {
		return !isNil(this.getFaxEmpfaengerControl(aufgabenTyp));
	}

	public enableFaxEmpfaenger(aufgabenTyp: AufgabenTyp) {
		this.getFaxEmpfaengerControl(aufgabenTyp).enable();
	}

	public disableFaxEmpfaenger(aufgabenTyp: AufgabenTyp) {
		this.getFaxEmpfaengerControl(aufgabenTyp).disable();
	}

	public getFaxEmpfaengerControl(aufgabenTyp: AufgabenTyp): UntypedFormControl {
		const bodyGroup = <UntypedFormGroup>(<UntypedFormGroup>this.form.controls[aufgabenTyp])
			.controls[CreateAufgabeFormService.feldAufgabeBody];

		if (isNil(bodyGroup)) {
			return undefined;
		}
		return <UntypedFormControl>bodyGroup.controls[CreateAufgabeFormService.feldVersandFaxEmpfaenger];
	}

	ngOnDestroy(): void {
		if (!isNil(this.typeSubscription)) {
			this.typeSubscription.unsubscribe();
		}
		if (!isNil(this.aufgabeSubscription)) {
			this.aufgabeSubscription.unsubscribe();
		}
		this.unsubscribeMitteilung();
	}

	private unsubscribeMitteilung() {
		if (!isNil(this.mitteilungSubscription)) {
			this.mitteilungSubscription.unsubscribe();
		}
		this.mitteilungSubscription = null;
	}
}
