import {Resource} from '@ngxp/rest';
import {BinaryFileResource} from '@schir-int-client/binary-shared';

export interface Vorlage {
	name: string;
	fileId: string;
}

export interface CreateVorlage extends Vorlage {
	file: File;
}

export interface VorlageListResource extends Resource {}

export interface VorlageResource extends Vorlage, Resource {
	_embedded: {
		file: BinaryFileResource
	};
}
