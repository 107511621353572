import {Resource} from '@ngxp/rest';
import {ChangeEntryResource} from '@schir-int-client/register-change';

export interface AlternativeTextListResource extends Resource {}

export interface AlternativeTextResource extends AlternativeText, Resource {}

export interface AlternativeText {
	containOriginalText: boolean;
	value: string;
	spaltenEintragId: string;
}

export interface CreateAlternativeText {
	containOriginalText: boolean;
	value: string;
}

export interface FlaggenrechtWithAlternativeText {
	originalText: string;
	containOriginalText: boolean;
	value: string;
	alternativeTextResource: AlternativeTextResource;
	flaggenrechtResource: ChangeEntryResource;
}
