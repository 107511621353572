import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {DefaultProjectorFn} from '@ngrx/store/src/selector';
import {createEmptyStateResource, StateResource} from '@schir-int-client/ngrx-helpers';
import {isUndefined} from 'lodash-es';
import {SignatureResource, SignatureValidatorResponseResource, SignaturTaskResource} from './signatur.model';
import {signaturFeatureState, SignaturRootState} from './signatur.state';


export const selectFeature: MemoizedSelector<object, SignaturRootState, DefaultProjectorFn<SignaturRootState>> =
	createFeatureSelector<SignaturRootState>(signaturFeatureState);

export const EMPTY_STATE_RESOURCE: StateResource<SignatureResource> = createEmptyStateResource();

export const inProgressDialogOpenSelector = createSelector(selectFeature, (state: SignaturRootState) => {
	return state.signatur.inProgressDialog.isOpen;
});

export const signatureTaskSelector: MemoizedSelector<object, SignaturTaskResource, DefaultProjectorFn<SignaturTaskResource>> =
	createSelector(selectFeature, (state: SignaturRootState) => {
		return state.signatur.signaturTask;
	});

export function signatureByReferenceSelector(reference: string): MemoizedSelector<object, StateResource<SignatureResource>, DefaultProjectorFn<StateResource<SignatureResource>>> {
	const selectFunction = (state: SignaturRootState) => {
		let signature = state.signatur.signatures[reference];
		return isUndefined(signature) ? EMPTY_STATE_RESOURCE : signature;
	}
	return createSelector(selectFeature, selectFunction);
}

export const signatureValidatorResponsesSelector: MemoizedSelector<object, StateResource<SignatureValidatorResponseResource>> =
	createSelector(selectFeature, (state: SignaturRootState) => {
		return state.signatur.signatureValidatorResponse;
	});
