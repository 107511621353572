import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {mapToCanActivate, mapToCanActivateChild, RouterModule, Routes} from '@angular/router';
import {RestModule} from '@ngxp/rest';
import {AdressverwaltungModule} from '@schir-int-client/adressverwaltung';
import {AlternativeTextModule} from '@schir-int-client/alternative-text';
import {AufgabeModule} from '@schir-int-client/aufgabe';
import {TechModule} from '@schir-int-client/tech';
import {VerfahrenSharedModule} from '@schir-int-client/verfahren-shared';
import {VorgangModule} from '@schir-int-client/vorgang';
import {VerfuegungModule} from 'libs/verfuegung/src/lib/verfuegung.module';
import {CreateVerfahrenDialogComponent} from './create-verfahren-dialog/create-verfahren-dialog.component';
import {VerfahrenGlobalSearchComponent} from './verfahren-global-search/verfahren-global-search.component';
import {VerfahrenListComponent} from './verfahren-list/verfahren-list.component';
import {VerfahrenSingleButtonsComponent} from './verfahren-list/verfahren-single/verfahren-single-buttons/verfahren-single-buttons.component';
import {VerfahrenSingleComponent} from './verfahren-list/verfahren-single/verfahren-single.component';
import {VerfahrenMainComponent} from './verfahren-main/verfahren-main.component';
import {VerfahrenSearchSingleComponent} from './verfahren-search/verfahren-search-single/verfahren-search-single.component';
import {VerfahrenSearchComponent} from './verfahren-search/verfahren-search.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {AuthGuard} from '../../../guards/src';
import {AktenzeichenModule} from '@schir-int-client/aktenzeichen';

const routes: Routes = [
	{
		path: 'verfahren',
		component: VerfahrenMainComponent,
		canActivate: mapToCanActivate([AuthGuard]),
		canActivateChild: mapToCanActivateChild([AuthGuard]),
	},
	{
		path: 'verfahrenDetail/:verfahrenUri',
		loadChildren: () => import('@schir-int-client/register').then(m => m.RegisterModule),
	},
];

@NgModule({
	imports: [
		CommonModule,
		TechModule,
		VerfahrenSharedModule,
		FormsModule,
		ReactiveFormsModule,
		MatTableModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatIconModule,
		MatTabsModule,
		MatTooltipModule,
		MatOptionModule,
		MatSelectModule,
		MatCheckboxModule,
		MatFormFieldModule,
		ScrollingModule,
		RestModule,
		RouterModule.forChild(routes),
		MatInputModule,
		FormsModule,
		VerfuegungModule,
		VorgangModule,
		AufgabeModule,
		AdressverwaltungModule,
		MatProgressSpinnerModule,
		AlternativeTextModule,
		MatMenuModule,
		MatListModule,
		AktenzeichenModule,
	],
	declarations: [
		VerfahrenListComponent,
		VerfahrenSearchComponent,
		VerfahrenSingleComponent,
		VerfahrenSearchSingleComponent,
		CreateVerfahrenDialogComponent,
		VerfahrenGlobalSearchComponent,
		VerfahrenMainComponent,
		VerfahrenSingleButtonsComponent,
	],
	exports: [
		VerfahrenListComponent,
		VerfahrenSearchComponent,
		VerfahrenSingleComponent,
		VerfahrenGlobalSearchComponent,
	],
})
export class VerfahrenModule {}
