import {Resource} from '@ngxp/rest';

export interface SignaturTask {
	status: string;
}

export interface SignaturTaskResource extends Resource {}

export interface Signature {
	signDate: string,
	nameOfSigner: string
}

export interface SignatureResource extends Signature, Resource {}

export interface SignatureValidatorResponseMessage {
	message: string;
}

export interface SignatureValidatorResponse {
	createdAt: string,
	signatureType: string,
	errorMessages: [SignatureValidatorResponseMessage]
}

export interface SignatureValidatorResponseResource extends SignatureValidatorResponse, Resource {}

export interface SignatureValidatorResponseListResource extends Resource {}
